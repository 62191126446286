import React from 'react'
import Helmet from 'react-helmet'

import { ChapterWrapper, ChapterHl as Hl, Steps, ChapterSpacer } from 'components/Styled'

const options = [
  {
    to: `/after/celebrate`,
    label: "Let’s celebrate!",
  },
  {
    to: `/after/feedback`,
    label: "What went well?",
  },
  {
    to: `/after/share`,
    label: "Share your success",
  },
]

const Index = () => {
  return (
    <ChapterWrapper>
      <Helmet>
        <title>Thimble | Celebrate</title>
      </Helmet>
      <Hl>Finish up with fun activities</Hl>
      
      <Steps 
        theme={{ bg: "var(--vanilla)", color: "var(--forest)" }}
        options={options}
      />
      <ChapterSpacer />
    </ChapterWrapper>
  )
}

export default Index
