import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const GlobalStyles = () => {
	const location = useLocation()
	const [background, setBackground] = useState("var(--berry)")
	const [foreground, setForeground] = useState("var(--sky)")


	useEffect( () => {
    if( location.pathname.indexOf("prep") > -1 ){
      setForeground("var(--lavender)")
      setBackground("var(--berry)")
    }
    else if( location.pathname.indexOf("after") > -1 ){
      setForeground("var(--vanilla)")
      setBackground("var(--forest)")
    }else if( location.pathname.indexOf("during") > -1 ){
      setForeground("var(--sky)")
      setBackground("var(--lake)")
    }else if( location.pathname === "/" ){
      setForeground("var(--sky)")
      setBackground("var(--berry)")
    }else{
      setForeground("var(--lake)")
      setBackground("var(--sky)")
    }
    
  }, [location.pathname])
	
	return (
		<style>
			{`
				html ::-webkit-scrollbar{height:0;width:0;}
				body {
					--berry: #392070;
					--lavender: #D8CAFF;
					--sky: #A8D5FF;
					--lake: #0E4394;
					--vanilla: #FFE3BA;
					--forest: #106359;

					background: ${background};
					color: ${foreground};
					transition: color .3s, background .3s;
					will-change: color, background;
				}
				.appViewport {
					background: ${background};
					color: ${foreground};
					transition: color .3s, background .3s;
					will-change: color, background;
				}
			`}
		</style>
	)
}

export default GlobalStyles


