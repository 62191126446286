import React from 'react'

const Check = props => <svg 
	width="24" 
	height="24" 
	viewBox="0 0 24 24"
	xmlns="http://www.w3.org/2000/svg"
  fill="currentColor"
  stroke="none" 
  strokeLinecap="round" 
  strokeLinejoin="round"
  {...props}
>
<path d="M20.66,5.25a1,1,0,0,1,.09,1.41l-10.67,12a1,1,0,0,1-1.49,0l-5.34-6a1,1,0,1,1,1.5-1.32l4.58,5.15L19.25,5.34A1,1,0,0,1,20.66,5.25Z"/>
</svg>

export default Check