import React, { useContext, useEffect, createContext, useState } from 'react'
import styled from '@emotion/styled'
import { isEqual } from 'lodash'

import { useQueryParams } from 'components/utility/hooks'

import Settings from 'components/Settings'

const modals = [
	{
		type: "SETTINGS",
		component: Settings,
	},
]



export const ModalContext = createContext(null)
export const useModalContext = () => useContext(ModalContext)

const Modal = styled.div`
	bottom: 0;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 200;
`

const ModalController = props => {
	const { modal, setModal, stateModal, setStateModal } = useContext(ModalContext)
  const queryParams = useQueryParams()

  const modalRecord = modal ? modals.find( el => el.type === modal.type ) : stateModal ? modals.find( el => el.type === stateModal.type ) : null

	useEffect( () => {
    const { modal: modalType, ...params } = queryParams
    // const modalData = modalType ? modals.find( el => el.type === modalType?.toUpperCase() ) : null
    const reqModal = { type: modalType?.toUpperCase(), queryParams: modalRecord?.queryParams || [], ...params}
    
    if( 
      ( !modal && modalType ) ||
      ( modal && !isEqual(modal, reqModal) ) //Changed params of the sidebar eg. from subtask list
    ){
      setModal(reqModal)
    }else if( modal && !modalType ){
      setModal()
    }
  }, [queryParams])

  useEffect( () => {
    const handleKeydown = e => {
      if( e.key === 'Escape' ){
				setModal()	
				setStateModal()
      }
    }
    document.addEventListener('keydown', handleKeydown);
    return () => {
      document.removeEventListener('keydown', handleKeydown)
    }
  })


	if( !modalRecord ) return null

	return (
		<>
			{ 
				modalRecord ?
					<Modal {...modal} {...modalRecord}>
						<modalRecord.component {...modal} {...modalRecord} />
					</Modal>
				: null
			}
		</>
	)
}

export default ModalController

export const ModalProvider = ({ children }) => {
  const [modal, setModal] = useState()
  const [stateModal, setStateModal] = useState()

  return (
    <ModalContext.Provider 
    	value={{
        modal, 
        setModal,
        stateModal,
        setStateModal,
    	}}
    >
      { children }
    </ModalContext.Provider>
  )
}
