import React from 'react'

const Sticker = props => <svg 
	width="250" 
	height="250" 
	viewBox="0 0 250.15 249.57"
	xmlns="http://www.w3.org/2000/svg"
  fill="currentColor"
  stroke="none"
  strokeLinecap="round" 
  strokeLinejoin="round"
  {...props}
>
<path d="M117,4.17a14.48,14.48,0,0,1,20.72.4,14.47,14.47,0,0,0,17,3,14.48,14.48,0,0,1,19.59,6.77,14.45,14.45,0,0,0,15.26,8.15A14.47,14.47,0,0,1,206.13,35a14.48,14.48,0,0,0,12,12.47,14.48,14.48,0,0,1,11.86,17A14.46,14.46,0,0,0,237.56,80a14.48,14.48,0,0,1,6,19.83A14.46,14.46,0,0,0,246,117a14.47,14.47,0,0,1-.39,20.72,14.46,14.46,0,0,0-3,17,14.47,14.47,0,0,1-6.78,19.59,14.46,14.46,0,0,0-8.14,15.26,14.47,14.47,0,0,1-12.5,16.53,14.48,14.48,0,0,0-12.46,12,14.48,14.48,0,0,1-17,11.86,14.47,14.47,0,0,0-15.57,7.56,14.47,14.47,0,0,1-19.82,6A14.46,14.46,0,0,0,133.15,246a14.47,14.47,0,0,1-20.72-.39,14.47,14.47,0,0,0-17-3,14.46,14.46,0,0,1-19.58-6.78,14.48,14.48,0,0,0-15.27-8.14A14.48,14.48,0,0,1,44,215.13a14.46,14.46,0,0,0-12-12.46,14.47,14.47,0,0,1-11.86-17,14.49,14.49,0,0,0-7.56-15.57,14.46,14.46,0,0,1-6-19.82,14.48,14.48,0,0,0-2.39-17.14,14.48,14.48,0,0,1,.4-20.72,14.49,14.49,0,0,0,3-17,14.46,14.46,0,0,1,6.77-19.58,14.48,14.48,0,0,0,8.15-15.27A14.47,14.47,0,0,1,35,44,14.46,14.46,0,0,0,47.48,32a14.46,14.46,0,0,1,17-11.86A14.47,14.47,0,0,0,80,12.59a14.47,14.47,0,0,1,19.83-6A14.48,14.48,0,0,0,117,4.17Z"/>
</svg>

export default Sticker