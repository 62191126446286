import React from 'react'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'

import Logo from 'components/svg/Logo'
import Gear from 'components/svg/Gear'
import Close from 'components/svg/Close'
import { useModalContext } from 'components/ModalController'

const Wrapper = styled.div`
  ${props => props.theme.isSettings ? `color: #000;` : null}
  align-items: center;
  display: flex;
  padding: 6rem 2.2rem 1rem 3rem;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 300;
  @media( max-width: 340px ){
    padding-left: 2rem;
    padding-right: 1.2rem;
  }
`
const NavLogo = styled(Logo)`
  height: 2.4rem;
  width: 11.6rem;
`
const SettingsBtn = styled.button`
  display: block;
  margin-left: auto;
  background: 0;
  outline: 0;
  border: 0;
  padding: .8rem;
`

const MainNav = () => {
  const { stateModal, setStateModal } = useModalContext()

  const settingsClick = () => {
    setStateModal( val => !val ? { type: "SETTINGS" } : null)
  }

  return (
    <Wrapper theme={{ isSettings: stateModal?.type === "SETTINGS" }}>
      <Link to="/" onClick={() => setStateModal()}>
        <NavLogo />
      </Link>
      <SettingsBtn onClick={settingsClick}>{ stateModal?.type === "SETTINGS" ? <Close /> : <Gear />  }</SettingsBtn>
    </Wrapper>
  );
}

export default MainNav
