import React, { useState } from 'react'
import styled from '@emotion/styled'
import Rive from 'rive-react'
import { SwitchTransition, CSSTransition } from "react-transition-group";
// import { keyframes } from '@emotion/react'

import { useTimeout } from 'components/utility/hooks'
import { LargeType, HugeType, Steps, Body, NoWrap } from 'components/Styled'
import { SlideAnimation } from 'components/styled/animations'

import Butterfly from 'components/svg/Butterfly'
import Cookie from 'components/svg/Cookie'
import Pentagon from 'components/svg/Pentagon'
import Sticker from 'components/svg/Sticker'
import Pinto from 'components/svg/Pinto'
import SadPinto from 'components/svg/SadPinto'
import Sun from 'components/svg/Sun'
import Urchin from 'components/svg/Urchin'

// const rotation = keyframes`
//   from {
//     transform: rotate(0deg);
//   }
//   to {
//     transform: rotate(359deg);
//   }
// `

const StepsWrapper = styled.div`
	margin-top: 4rem;
	width: 100%;
`
const StyledRive = styled(Rive)`
	height: 28rem;
	position: relative;
	width: 28rem;
  @media( max-width: 340px ){
    height: 18rem;
    width: 18rem;
  }
`

const CharacterFrame = styled.div`
	align-items: center;
	display: flex;
	height: 28rem;
	justify-content: center;
	margin: 0 auto;
	position: relative;
	width: 28rem;
  @media( max-width: 340px ){
    height: 18rem;
    width: 18rem;
  }
`
const PentagonBg = styled(Pentagon)`
	top: 50%;
	left: 50%;
	position: absolute;
	transform: translate(-50%, -50%);
  @media( max-width: 340px ){
    height: 18rem;
    width: 18rem;
  }
`
const StickerBg = styled(Sticker)`
	color: #fff;
	top: 50%;
	left: 50%;
	position: absolute;
	transform: translate(-50%, -50%);
  @media( max-width: 340px ){
    height: 18rem;
    width: 18rem;
  }
`
const CenterBody = styled(Body)`
	text-align: center;
	ul {
		text-align: left;
		padding-left: 1em;
	}
	ul,
  ol,
  p {
    margin: 1em 0;
  }
`
export const SizedBody1 = styled(Body)`
	text-align: center;
	height: 4.5rem;
	margin-top: 2rem;
	@media( min-height: 700px ){
		height: 10rem;
	}
`
export const SizedBody2 = styled(Body)`
	text-align: center;
	height: 14rem;
	margin-top: 2rem;
	@media( min-height: 700px ){
		height: 14rem;
	}
`
const CenterLargeType = styled(LargeType)`
	text-align: center;
`
const CenterHugeType = styled(HugeType)`
	text-align: center;
`

const Content = styled.div`
	padding: 2rem 3rem;
	height: 100%;
  @media( max-width: 340px ){
    padding-left: 2rem;
    padding-right: 2rem;
  }
`
const CenterContent = styled(Content)`
	align-items: center;
	display: flex;
	justify-content: center;
	flex-direction: column;
`




const prep = {
	id: "list",
	title: "How to prepare for needle procedures",
	pageTitle: "How to prepare for needle procedures",
	path: `/prep`,
	theme: {
		bg: "var(--lavender)",
		color: "var(--berry)",
	},
	steps: [
		{
			cta: "BEGIN",
			content: (
				<CenterContent>
				<CenterBody>
					<p>Getting prepared is an important part of the process. Good job <NoWrap>starting early!</NoWrap></p>
					<p>Kids, snuggle up with your grown-up. Let’s get ready together. </p>
				</CenterBody>
				</CenterContent>
			)
		},
		{
			cta: "NEXT",
			content: (
				<CenterContent>
					<CharacterFrame>
						<SadPinto />
					</CharacterFrame>
					<SizedBody2>
						<p>It’s okay to be nervous or worried. Lots of people feel this way.</p>
						<p>Doesn’t matter how big or little you are, it can feel scary.</p>
					</SizedBody2>
				</CenterContent>
			)
		},
		{
			cta: "NEXT",
			content: (
				<CenterContent>
					<CharacterFrame>
						<Urchin />
					</CharacterFrame>
					<SizedBody2>
						<p>That’s because it's in our body’s biology to react this way when we feel threatened. The good news is, this isn’t a threat.</p>
					</SizedBody2>
				</CenterContent>
			)
		},
		{
			cta: "NEXT",
			content: (
				<CenterContent>
					<CharacterFrame>
						<Cookie />
					</CharacterFrame>
					<SizedBody2>
						<p>In fact it’s just the opposite—it’s going to keep you strong and healthy. We have lots of ways to help you feel safe. Now, let’s get ready for your appointment!</p>
					</SizedBody2>
				</CenterContent>
			)
		},
		{
			cta: "FINISH",
			content: (
				<Content>
					<Body><p>Click through our prep list for some proven tips and tricks to make the whole experience as painless as possible.</p></Body>
					<StepsWrapper>
						<Steps
							theme={{ bg: "#fff", color: "var(--berry)" }}
							options={[
								{
									to: `/prep/calming-reminders`,
									label: "Calming reminders",
								},
								{
									to: `/prep/comfort-position`,
									label: "Comfort position",
								},
								{
									to: `/prep/talk-it-out`,
									label: "Talk it out",
								}
							]}
						/>
					</StepsWrapper>
				</Content>
			)
		}
	]
}

const calmingReminders = {
	id: "calming-reminders",
	type: "ROTATOR",
	backPath: `/prep/list/5`,
	dismissPath: `/prep/list/5`,
	finishPath: `/prep/list/5`,
	title: "Calming reminders",
	pageTitle: "Calming reminders",
	theme: {
		bg: "var(--lavender)",
		color: "var(--berry)",
	},
	steps: [
		{
			content: (
				<CenterBody><p>Remember the process will be quick, the pain won’t last, and grown-ups will stay close.</p></CenterBody>
			)
		},
		{
			content: (
				<CenterBody><p>Using neutral language helps everyone to feel more comfortable.</p></CenterBody>
			)
		}
	]
}

const comfortPosition = {
	id: "comfort-position",
	type: "ROTATOR",
	backPath: `/prep/list/5`,
	dismissPath: `/prep/list/5`,
	finishPath: `/prep/list/5`,
	title: "Comfort position",
	pageTitle: "Comfort position",
	theme: {
		bg: "var(--lavender)",
		color: "var(--berry)",
	},
	steps: [
		{
			content: (
				<CenterBody>
					<p>Choosing your preferred comfort position can help you feel secure and stay still, which makes things less scary and less painful.</p>
					<p>Now, let’s find yours:</p>
				</CenterBody>
			)
		},
		{
			content: (
				<CenterBody><p>Is it seated?</p></CenterBody>
			)
		},
		{
			content: (
				<CenterBody><p>On a lap?</p></CenterBody>
			)
		},
		{
			content: (
				<CenterBody><p>Lying down, <br />or something else?</p></CenterBody>
			)
		},
		{
			content: (
				<CenterBody>
					<p>Don’t worry, you won’t be alone.</p>
					<p>And you can change your comfort position if you need to, just tell <NoWrap>your grown-up.</NoWrap></p>
				</CenterBody>
			)
		}
	]
}

const talkItOut = {
	id: "talk-it-out",
	type: "ROTATOR",
	backPath: `/prep/list/5`,
	dismissPath: `/prep/list/5`,
	finishPath: `/prep/list/5`,
	title: "Talk it out",
	pageTitle: "Talk it out",
	theme: {
		bg: "var(--lavender)",
		color: "var(--berry)",
	},
	steps: [
		{
			content: (
				<CenterBody><p>Before your appointment, chat with your grown-up about how you want things to go.</p></CenterBody>
			)
		},
		{
			content: (
				<CenterBody>
					<p>Some kids want to know what’s happening and when.</p>
					<p>Others don’t.</p>
				</CenterBody>
			)
		},
		{
			content: (
				<CenterBody>
					<p>Some kids want to look.</p>
					<p>Others don’t.</p>
				</CenterBody>
			)
		},
		{
			content: (
				<CenterBody><p>Which do you prefer? It’s okay to change your mind later, just tell your grown-up.</p></CenterBody>
			)
		}
	]
}

const StretchRive = styled(Rive)`
	height: 26.6rem;
	position: relative;
	width: 26.6rem;
  @media( max-width: 340px ){
    height: 18rem;
    width: 18rem;
  }
`

const strong = {
	id: "strong",
	title: "Get Ready",
	pageTitle: "Get Ready",
	path: `/during`,
	theme: {
		bg: "var(--sky)",
		color: "var(--lake)",
	},
	steps: [
		{
			cta: "BEGIN",
			content: (
				<CenterContent>
					<CenterBody>
						<p>Stretching your muscles about 5 minutes before the needle poke can help you relax.</p>
						<p>Ready? We’ll guide you.</p>
					</CenterBody>
				</CenterContent>
			)
		},
		{
			cta: "NEXT",
			content: (
				<CenterContent>
					<CenterLargeType>First take a minute to appreciate that you’re strong and brave.</CenterLargeType>
				</CenterContent>
			)
		},
		{
			cta: "NEXT",
			content: (
				<CenterContent>
					<CharacterFrame>
						<Pinto />
					</CharacterFrame>
					<SizedBody1>
						<p>Alright, let’s go!</p>
					</SizedBody1>
				</CenterContent>
			)
		},
		{
			cta: "NEXT",
			content: (
				<CenterContent>
					<CenterLargeType>Pay attention to your body. How does it feel?</CenterLargeType>
				</CenterContent>
			)
		},
		{
			cta: "NEXT",
			content: (
				<CenterContent>
					<CenterLargeType>Start by flexing both your arms.</CenterLargeType>
				</CenterContent>
			)
		},
		{
			cta: "NEXT",
			content: (
				<CenterContent>
					<CharacterFrame>
						<PentagonBg />
						<StretchRive key="stretch" src="/_media/animation/stretch_no_rainbow_lines.riv" />
					</CharacterFrame>
					<SizedBody1>
						<p>Inhale <br />and hold your arms out like wings.</p>
					</SizedBody1>
				</CenterContent>
			)
		},
		{
			cta: "NEXT",
			content: (
				<CenterContent>
					<CharacterFrame>
						<PentagonBg />
						<StretchRive key="flex" src="/_media/animation/flex_lines.riv" />
					</CharacterFrame>
					<SizedBody1>
						<p>Exhale <br />and curl them up by your ears.</p>
					</SizedBody1>
				</CenterContent>
			)
		},
		{
			cta: "NEXT",
			content: (
				<CenterContent>
					<CharacterFrame>
						<PentagonBg />
						<StretchRive key="arm" src="/_media/animation/arm_lines.riv" />
					</CharacterFrame>
					<SizedBody1>
						<p>Flex for 7 seconds.</p>
					</SizedBody1>
				</CenterContent>
			)
		},
		{
			cta: "NEXT",
			content: (
				<CenterContent>
					<CenterLargeType>You’re doing great! Let’s flex again.</CenterLargeType>
				</CenterContent>
			)
		},
		{
			cta: "NEXT",
			content: (
				<CenterContent>
					<CenterLargeType>Get as big and strong as you can.</CenterLargeType>
				</CenterContent>
			)
		},
		{
			cta: "NEXT",
			content: (
				<CenterContent>
					<CharacterFrame>
						<PentagonBg />
						<StretchRive key="stretch" src="/_media/animation/stretch_no_rainbow_lines.riv" />
					</CharacterFrame>
					<SizedBody1>
						<p>Inhale and stretch.</p>
					</SizedBody1>
				</CenterContent>
			)
		},
		{
			cta: "NEXT",
			content: (
				<CenterContent>
					<CharacterFrame>
						<PentagonBg />
						<StretchRive key="flex" src="/_media/animation/flex_lines.riv" />
					</CharacterFrame>
					<SizedBody1>
						<p>Exhale and flex.</p>
					</SizedBody1>
				</CenterContent>
			)
		},
		{
			cta: "NEXT",
			content: (
				<CenterContent>
					<CharacterFrame>
						<PentagonBg />
						<StretchRive key="arm" src="/_media/animation/arm_lines.riv" />
					</CharacterFrame>
					<SizedBody1>
						<p>Flex for 7 seconds.</p>
					</SizedBody1>
				</CenterContent>
			)
		},
		{
			cta: "NEXT",
			content: (
				<CenterContent>
					<CenterLargeType>Great job! Now let’s get the wiggles out.</CenterLargeType>
				</CenterContent>
			)
		},
		{
			cta: "NEXT",
			content: (
				<CenterContent>
					<CenterLargeType>Shake your whole body. Fingers, shoulders, knees, toes.</CenterLargeType>
				</CenterContent>
			)
		},
		{
			cta: "NEXT",
			content: (
				<CenterContent>
					<CharacterFrame>
						<StyledRive key="wiggle" src="/_media/animation/wiggle.riv" />
					</CharacterFrame>
					<SizedBody1>
						<p>Wiggle for 7 seconds.</p>
					</SizedBody1>
				</CenterContent>
			)
		},
		{
			cta: "NEXT",
			content: (
				<CenterContent>
					<CenterLargeType>Great job!</CenterLargeType> <br />
					<CenterLargeType>Now take a big inhale and on the exhale let’s hear a HUZZAH!</CenterLargeType>
				</CenterContent>
			)
		},
		{
			cta: "DID_IT",
			content: (
				<CenterContent>
					<CenterLargeType>Did you do it?!</CenterLargeType>
				</CenterContent>
			)
		},
		{
			cta: "CONFETTI",
			duration: 3000,
			content: (
				<CenterContent>
					<CenterHugeType>Great job!</CenterHugeType>
				</CenterContent>
			)
		},
		{
			cta: "NEXT",
			content: (
				<CenterContent>
					<CenterLargeType>You’re amazing! Congratulate yourself for being so strong.</CenterLargeType>
				</CenterContent>
			)
		},
		{
			cta: "FINISH",
			content: (
				<CenterContent>
					<CharacterFrame>
						<Pinto />
					</CharacterFrame>
					<SizedBody2>
						<p>Take a deep breathe and relax. You can do this as many times as you need to feel strong and brave.</p>
						<p>You got this!</p>
					</SizedBody2>
				</CenterContent>
			)
		},
	]
}


const AnimationWrapper = styled(SlideAnimation)`
	align-items: center;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	padding: 2rem 3rem;
`
const InhaleRive = styled(Rive)`
	height: 34rem;
	position: relative;
	width: 34rem;
	min-width: 34rem;
  @media( max-width: 340px ){
    height: 18rem;
    width: 18rem;
  }
`
const BubbleRive = styled(Rive)`
	height: 29.4rem;
	position: relative;
	width: 29.4rem;
  @media( max-width: 340px ){
    height: 18rem;
    width: 18rem;
  }
`

const BubbleSlide = () => {
	const [triggered, setTriggered] = useState(0)
	
	useTimeout(() => {
    setTriggered(1)
  }, 2000)

	return (
		<AnimationWrapper>
			<CenterContent>
				<CharacterFrame>
					<StickerBg />
					<BubbleRive key="bubble1" src="/_media/animation/bubble_1_lines.riv" />
				</CharacterFrame>
				<SwitchTransition>
	        <CSSTransition
	          key={triggered}
	          addEndListener={(node, done) => {
	            node.addEventListener("transitionend", done, false);
	          }}
	          classNames="slideLeftFade"
	        >
					<SizedBody1>
					{ 
						!triggered ? 
						<p className="slideItem">Big breath out.</p> : 
						<p className="slideItem">Pop!</p>
					}
					</SizedBody1>
					</CSSTransition>
				</SwitchTransition>
			</CenterContent>
		</AnimationWrapper>
	)
}

const breath = {
	id: "breath",
	title: "Take a Breath",
	pageTitle: "Take a Breath",
	path: `/during`,
	theme: {
		bg: "var(--sky)",
		color: "var(--lake)",
	},
	steps: [
		{
			cta: "BEGIN",
			content: (
				<CenterContent>
					<CenterBody>
						<p>Taking deep, slow breaths can help relax your muscles and ease your nerves right before a needle poke.</p>
						<p>We’ll show you how.</p>
					</CenterBody>
				</CenterContent>
			)
		},
		{
			cta: "NEXT",
			content: (
				<CenterContent>
					<CenterLargeType>We’re going to blow some bubbles together.</CenterLargeType>
				</CenterContent>
			)
		},
		{
			cta: "NEXT",
			content: (
				<CenterContent>
					<CharacterFrame>
						<Cookie />
					</CharacterFrame>
					<SizedBody1>
						<p>Ready? Let’s get started!</p>
					</SizedBody1>
				</CenterContent>
			)
		},
		{
			cta: "NEXT",
			content: (
				<CenterContent>
					<CenterLargeType>We’re going to pretend we’re chewing bubblegum.</CenterLargeType>
				</CenterContent>
			)
		},
		{
			cta: "NEXT",
			content: (
				<CenterContent>
					<CenterLargeType>Chew, chew, chew.</CenterLargeType>
				</CenterContent>
			)
		},
		{
			cta: "NEXT",
			content: (
				<CenterContent>
					<CenterLargeType>Now, take a big, slow breath in.</CenterLargeType>
				</CenterContent>
			)
		},
		{
			cta: "NEXT",
			content: (
				<CenterContent>
					<CharacterFrame>
						<InhaleRive 
							key="inhale" 
							src="/_media/animation/inhale_one-shot.riv" 
						/>
					</CharacterFrame>
					<SizedBody1>
						<p>Big breath in.</p>
					</SizedBody1>
				</CenterContent>
			)
		},
		{
			cta: "NEXT",
			content: (
				<CenterContent>
					<CenterLargeType>Then blow all your air into a bubble.</CenterLargeType>
				</CenterContent>
			)
		},
		{
			cta: "NEXT",
			content: <BubbleSlide />
		},
		{
			cta: "NEXT",
			content: (
				<CenterContent>
					<CenterLargeType>Let’s try again.</CenterLargeType>
				</CenterContent>
			)
		},
		{
			cta: "NEXT",
			content: (
				<CenterContent>
					<CenterLargeType>Chew, chew, chew.</CenterLargeType>
				</CenterContent>
			)
		},
		{
			cta: "NEXT",
			content: (
				<CenterContent>
					<CharacterFrame>
						<InhaleRive 
							key="inhale2" 
							src="/_media/animation/inhale_one-shot.riv" 
						/>
					</CharacterFrame>
					<SizedBody1>
						<p>Take a big, slow breath in.</p>
					</SizedBody1>
				</CenterContent>
			)
		},
		{
			cta: "NEXT",
			content: (
				<CenterContent>
					<CharacterFrame>
						<StickerBg />
						<BubbleRive key="bubble3" src="/_media/animation/bubble_2_lines.riv" />
					</CharacterFrame>
					<SizedBody1>
					<p>This time blow out slowly, so the bubble doesn’t pop.</p>
					</SizedBody1>
				</CenterContent>
			)
		},
		{
			cta: "NEXT",
			content: (
				<CenterContent>
					<CenterLargeType>Good job! <br />Let’s do it one more time!</CenterLargeType>
				</CenterContent>
			)
		},
		{
			cta: "NEXT",
			content: (
				<CenterContent>
					<CenterLargeType>Chew, chew, chew.</CenterLargeType>
				</CenterContent>
			)
		},
		{
			cta: "NEXT",
			content: (
				<CenterContent>
					<CenterLargeType>Go slow so your bubble doesn’t pop.</CenterLargeType>
				</CenterContent>
			)
		},
		{
			cta: "NEXT",
			content: (
				<CenterContent>
					<CharacterFrame>
						<InhaleRive 
							key="inhale3" 
							src="/_media/animation/inhale_one-shot.riv" 
						/>
					</CharacterFrame>
					<SizedBody1>
						<p>Take a big, slow breath in.</p>
					</SizedBody1>
				</CenterContent>
			)
		},
		{
			cta: "NEXT",
			content: (
				<CenterContent>
					<CharacterFrame>
						<StickerBg />
						<BubbleRive key="bubble3" src="/_media/animation/bubble_3_lines.riv" />
					</CharacterFrame>
					<SizedBody1>
					<p>Slow breath out.</p>
					</SizedBody1>
				</CenterContent>
			)
		},
		{
			cta: "DID_IT",
			content: (
				<CenterContent>
					<CenterLargeType>Did you do it?!</CenterLargeType>
				</CenterContent>
			)
		},
		{
			cta: "CONFETTI",
			duration: 3000,
			content: (
				<CenterContent>
					<CenterHugeType>Great job!</CenterHugeType>
				</CenterContent>
			)
		},
		{
			cta: "NEXT",
			content: (
				<CenterContent>
					<CenterLargeType>You’re a bubble pro!</CenterLargeType>
				</CenterContent>
			)
		},
		{
			cta: "FINISH",
			content: (
				<CenterContent>
					<CharacterFrame>
						<Cookie />
					</CharacterFrame>
					<SizedBody2>
						<p>Now let’s find a cozy, comfy position with your grown-up. You can keep blowing bubbles after you get snuggled in too.</p>
					</SizedBody2>
				</CenterContent>
			)
		},

	]
}

const silly = {
	id: "silly",
	title: "Get silly",
	pageTitle: "Get silly",
	path: `/during`,
	theme: {
		bg: "var(--sky)",
		color: "var(--lake)",
	},
	steps: [
		{
			cta: "BEGIN",
			content: (
				<CenterContent>
					<CenterLargeType>Coming soon!</CenterLargeType>
				</CenterContent>
			)
		},
	]
}


const SizedSun = styled(Sun)`
  @media( max-width: 340px ){
    height: 18rem;
    width: 18rem;
  }
`

const celebrate = {
	id: "celebrate",
	title: "Let’s celebrate!",
	pageTitle: "Let’s celebrate!",
	path: `/after`,
	theme: {
		bg: "var(--forest)",
		color: "var(--vanilla)",
	},
	steps: [
		{
			cta: "CONFETTI",
			duration: 3000,
			linkTo: `/after/celebrate-ideas`,
			content: (
				<CenterContent>
					<SizedSun />
					<CenterHugeType>You <NoWrap>did it!</NoWrap></CenterHugeType>
				</CenterContent>
			)
		}
	]
}

const IdeaIntro = styled(Body)`
	display: flex;
	flex-direction: column;
	height: 100%;
`
const IdeaHl = styled.p`
	text-align: center;
`
const IdeaBody = styled.div`
	max-width: 28rem;
	margin: auto 0;
`

const celebrateIdeas = {
	id: "celebrate-ideas",
	type: "ROTATOR",
	backPath: `/after/celebrate/1`,
	dismissPath: `/after`,
	finishPath: `/after`,
	title: "Let’s celebrate!",
	pageTitle: "Let’s celebrate!",
	theme: {
		bg: "var(--forest)",
		color: "var(--vanilla)",
	},
	steps: [
		{
			className: "introSlide",
			content: (
				<IdeaIntro>
					<IdeaHl>Now for the best part—let’s celebrate!</IdeaHl>
					<IdeaBody>
						<p>Here are some fun celebration ideas you can do now that you’re all done, pick one of ours or come up with <NoWrap>your own!</NoWrap></p>
					</IdeaBody>
				</IdeaIntro>
			)
		},
		{
			content: (
				<CenterBody><p>Go to your favorite park</p></CenterBody>
			)
		},
		{
			content: (
				<CenterBody><p>Build a blanket fort</p></CenterBody>
			)
		},
		{
			content: (
				<CenterBody><p>Have a movie night</p></CenterBody>
			)
		},
		{
			content: (
				<CenterBody><p>Sing-along to your favorite song</p></CenterBody>
			)
		},
		{
			content: (
				<CenterBody><p>Draw a picture of things <br />that make you feel brave</p></CenterBody>
			)
		},
		{
			content: (
				<CenterBody><p>Play a game</p></CenterBody>
			)
		},
		{
			content: (
				<CenterBody><p>Check out a whole stack of library books</p></CenterBody>
			)
		},
		{
			content: (
				<CenterBody><p>Make something delicious <NoWrap>to eat</NoWrap></p></CenterBody>
			)
		},
		{
			content: (
				<CenterBody><p>Have a dance party</p></CenterBody>
			)
		}
	]
}

const feedback = {
	id: "feedback",
	title: "What went well?",
	pageTitle: "Feedback",
	path: `/after`,
	theme: {
		bg: "var(--forest)",
		color: "var(--vanilla)",
	},
	steps: [
		{
			cta: "BEGIN",
			content: (
				<CenterContent>
					<CenterBody>
						<p>You did it! You’re so brave!</p>
						<p>It’s important to talk about the whole experience, that way you can reflect on the parts that went well and what to change for next time.</p>
						<p>That’s how healthy habits are formed.</p>
					</CenterBody>
				</CenterContent>
			)
		},
		{
			cta: "NEXT",
			content: (
				<CenterContent>
					<CenterBody>
						<p>You can just talk, or if you want to take it a step further you can:</p>
						<ul>
							<li>Write down your answers</li>
							<li>Record a video</li>
							<li>Or snap a picture.</li>
						</ul>
						<p>&hellip;to share with someone later</p>
					</CenterBody>
				</CenterContent>
			)
		},
		{
			cta: "NEXT",
			linkTo: `/after/feedback-ideas`,
			content: (
				<CenterContent>
					<CharacterFrame>
						<Butterfly />
					</CharacterFrame>
					<CenterLargeType>Whatever works best for you!</CenterLargeType>
				</CenterContent>
			)
		},
		{
			cta: "NEXT",
			content: (
				<CenterContent>
					<CenterLargeType>Thanks for <br />sharing!</CenterLargeType>
				</CenterContent>
			)
		},
		{
			cta: "NEXT",
			content: (
				<CenterContent>
					<CenterBody>
						<p>Give yourself an extra little pat on the back, you deserve it.</p>
					</CenterBody>
				</CenterContent>
			)
		},
		{
			cta: "FINISH",
			content: (
				<CenterContent>
					<CharacterFrame>
						<Butterfly />
					</CharacterFrame>
					<SizedBody2>
						<p>If you chose to write or record your answers, share it with someone who would love to hear about it. Who do you want to share it with?</p>
					</SizedBody2>
				</CenterContent>
			)
		},


	]
}

const FeedbackIntro = styled.div`
	max-width: 28rem;
`

const feedbackIdeas = {
	id: "feedback-ideas",
	type: "ROTATOR",
	backPath: `/after/feedback/4`,
	dismissPath: `/after`,
	finishPath: `/after/feedback/5`,
	title: "What went well?",
	pageTitle: "What went well?",
	theme: {
		bg: "var(--forest)",
		color: "var(--vanilla)",
	},
	steps: [
		{
			className: "introSlide",
			content: (
				<FeedbackIntro>
					<p>So, let’s take a second to talk about <NoWrap>your experience.</NoWrap></p>
				</FeedbackIntro>
			)
		},
		{
			content: (
				<CenterBody><p>How did it go?</p></CenterBody>
			)
		},
		{
			content: (
				<CenterBody><p>What are you proud of?</p></CenterBody>
			)
		},
		{
			content: (
				<CenterBody><p>What do you think helped the process go well?</p></CenterBody>
			)
		},
		{
			content: (
				<CenterBody><p>What would you do <br />differently next time?</p></CenterBody>
			)
		},
		{
			content: (
				<CenterBody><p>What would you tell other kids that might be scared? </p></CenterBody>
			)
		},
		{
			content: (
				<CenterBody><p>Anything else you wanna chat about?</p></CenterBody>
			)
		},
	]
}


const chapters = [
	prep,
		calmingReminders,
		comfortPosition,
		talkItOut,
	strong,
	breath,
	silly,
	celebrate,
		celebrateIdeas,
		feedbackIdeas,
	feedback,
]
export default chapters