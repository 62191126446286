import React, { useEffect } from 'react'
import { Switch, Route, useLocation, useHistory } from 'react-router-dom'
import Helmet from 'react-helmet'
import styled from '@emotion/styled'
import vhCheck from 'vh-check'

import ModalController, { ModalProvider } from 'components/ModalController'
import GlobalStyles from 'components/styled/Global'
import MainNav from 'components/MainNav'
import ChapterNav from 'components/ChapterNav'
import Index from 'components/Index'
import During from 'components/During'
import After from 'components/After'
import Prep from 'components/Prep'
import Share from 'components/Share'
import Chapter from 'components/Chapter'
import NotFound from 'components/NotFound'
import Game from 'components/Game'
import Login from 'components/Login'

const Wrapper = styled.div`
  height: 100vh;
  height: calc(100vh - var(--vh-offset, 0px));
  align-items: center;
  background: #222;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 54rem;
`
const Inner = styled.div`
  height: 100%;
  position: relative;
  width: 100%;
  @media( min-width: 768px ){
    border-radius: 1rem;
    height: 72rem;
    max-width: 40rem;
    overflow: hidden;
    width: 100%;
  }
`
const Disclaimer = styled.p`
  display: none;
  margin-top: 4rem;
  max-width: 40rem;
  font-size: 2.1rem;
  color: #464646;
  text-align: center;
  @media( min-height: 860px ) and ( min-width: 1024px ){
    display: block;
  }
`

const App = () => {
  const location = useLocation()

  return (
    <ModalProvider>
      <Helmet>
        <title>Thimble</title>
      </Helmet>
    <Wrapper>
      <Inner className="appViewport">
        <ModalController />

        { ["/", "/welcome", "/after/share", "/during/game" ].indexOf( location.pathname ) === -1 ? <MainNav /> : null }

        <Switch>
          <Route exact path="/"><Index /></Route>
          <Route exact path="/prep"><Prep /></Route>
          <Route path="/prep/:chapter/:page"><Chapter /></Route>
          <Route path="/prep/:chapter"><Chapter /></Route>
          <Route exact path="/during"><During /></Route>
          <Route exact path="/during/game"><Game /></Route>
          <Route path="/during/:chapter/:page"><Chapter /></Route>
          <Route path="/during/:chapter"><Chapter /></Route>
          <Route exact path="/after"><After /></Route>
          <Route exact path="/after/share"><Share /></Route>
          <Route path="/after/:chapter/:page"><Chapter /></Route>
          <Route path="/after/:chapter"><Chapter /></Route>
          <Route path="*"><NotFound /></Route>
        </Switch>

        <Switch>
          <Route path="/during/game"></Route>
          <Route path="/after/share"></Route>
          <Route path="/prep"><ChapterNav /></Route>
          <Route path="/during"><ChapterNav /></Route>
          <Route path="/after"><ChapterNav /></Route>
        </Switch>
      </Inner>

      <Disclaimer>This experience works best <br />on your mobile device.</Disclaimer>

    </Wrapper>
    </ModalProvider>
  )
}

const AppWithLogin = () => {

  const location = useLocation()
  const history = useHistory()

  useEffect( () => {
    const validated = localStorage.getItem("thimble_auth")
    if(validated === 'true' && location.pathname === "/login" ){
      history.push("/")
    }else if( validated !== 'true' && location.pathname !== "/login" ){
      history.push("/login")
    }
  }, [])

  useEffect(() => {
    vhCheck({
      updateOnTouch: true,
      cssVarName: 'vh-offset',
    })
  }, []);

  return (
    <>
      <Switch>
        <Route path="/login"><Login /></Route>
        <Route path="/"><App /></Route>
      </Switch>
      <GlobalStyles />
    </>
  )
}
export default AppWithLogin
