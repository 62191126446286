import React from 'react'

const Pinto = props => <svg 
	width="264" 
	height="250" 
	viewBox="0 0 264 250"
	xmlns="http://www.w3.org/2000/svg"
  fill="currentColor"
  stroke="none"
  strokeLinecap="round" 
  strokeLinejoin="round"
  {...props}
>
	<path fillRule="evenodd" d="M25.9,63.09,97.19,12.74a60.56,60.56,0,0,1,69.58,0l71.32,50.35a57.44,57.44,0,0,1,21.43,28.67,55.85,55.85,0,0,1,0,35.43l-27.25,81.45a57.37,57.37,0,0,1-21.47,28.69,60.14,60.14,0,0,1-34.73,10.92H87.91A60.31,60.31,0,0,1,53.2,237.3a57.57,57.57,0,0,1-21.5-28.66L4.46,127.19a55.89,55.89,0,0,1,0-35.42A57.61,57.61,0,0,1,25.9,63.09Zm129,47.41a12.59,12.59,0,0,1,8-2.93,12.26,12.26,0,0,1,7.75,3.15,3.06,3.06,0,0,0,4,0,2.64,2.64,0,0,0,.08-3.92,18.08,18.08,0,0,0-11.78-4.9,18.35,18.35,0,0,0-12,4.51,2.64,2.64,0,0,0-.1,3.92A3.05,3.05,0,0,0,154.91,110.5ZM95,123.89a12.57,12.57,0,0,1,6.81-5.1,12.28,12.28,0,0,1,8.33.79,3.08,3.08,0,0,0,3.89-1.11,2.64,2.64,0,0,0-1.06-3.78,18,18,0,0,0-12.69-1.32,18.38,18.38,0,0,0-10.22,7.77,2.64,2.64,0,0,0,1,3.78A3.07,3.07,0,0,0,95,123.89Zm51.59,8.17a12.69,12.69,0,0,1-7,4.88,12.32,12.32,0,0,1-8.3-1.07,3.08,3.08,0,0,0-3.92,1,2.64,2.64,0,0,0,.93,3.81A18,18,0,0,0,141,142.4,18.38,18.38,0,0,0,151.47,135a2.64,2.64,0,0,0-.91-3.82A3.05,3.05,0,0,0,146.63,132.06Z"/>
</svg>

export default Pinto