import React from 'react'

const Urchin = props => <svg 
	width="296" 
	height="296" 
	viewBox="0 0 296.4 293.43"
	xmlns="http://www.w3.org/2000/svg"
  fill="currentColor"
  stroke="none"
  strokeLinecap="round" 
  strokeLinejoin="round"
  {...props}
>
<path fillRule="evenodd" d="M145.11,61.87a2.8,2.8,0,0,1-5.06.34L108.91,5.75a2.8,2.8,0,0,0-5.24,1.57l5,64.28a2.8,2.8,0,0,1-4.41,2.49L51.63,36.77a2.8,2.8,0,0,0-4,3.69L80,96.22a2.8,2.8,0,0,1-2.89,4.17L13.47,89.55a2.8,2.8,0,0,0-2.05,5.07L64.8,130.86a2.8,2.8,0,0,1-.8,5L2,153.68a2.8,2.8,0,0,0,.36,5.46l63.78,9.47a2.79,2.79,0,0,1,1.45,4.85L19.5,216.41a2.8,2.8,0,0,0,2.7,4.76l61.61-19.12a2.79,2.79,0,0,1,3.41,3.74L62.51,265.34a2.8,2.8,0,0,0,4.5,3.12l47.18-44a2.8,2.8,0,0,1,4.7,1.89l3.58,64.4a2.8,2.8,0,0,0,5.41.86l23.41-60.08a2.8,2.8,0,0,1,5.06-.33l31.17,56.46a2.8,2.8,0,0,0,5.24-1.57l-4.95-64.31a2.8,2.8,0,0,1,4.41-2.5l52.57,37.34a2.8,2.8,0,0,0,4.05-3.68l-32.36-55.76a2.8,2.8,0,0,1,2.89-4.17l63.56,10.82A2.8,2.8,0,0,0,285,198.8l-53.35-36.22a2.8,2.8,0,0,1,.8-5l61.95-17.82a2.79,2.79,0,0,0-.37-5.45l-63.76-9.47A2.8,2.8,0,0,1,228.8,120l48.1-43a2.8,2.8,0,0,0-2.7-4.76L212.63,91.39a2.8,2.8,0,0,1-3.42-3.74l24.71-59.56A2.8,2.8,0,0,0,229.43,25l-47.19,44a2.8,2.8,0,0,1-4.7-1.89L174,2.65a2.8,2.8,0,0,0-5.4-.87Zm1.21,97.44a8.62,8.62,0,0,1,5.07-2.8,8.41,8.41,0,0,1,5.55,1.31,2.09,2.09,0,0,0,2.73-.39,1.79,1.79,0,0,0-.36-2.64,12.24,12.24,0,0,0-8.44-2.07,12.45,12.45,0,0,0-7.62,4.28,1.79,1.79,0,0,0,.34,2.65A2.09,2.09,0,0,0,146.32,159.31Zm-20-15.92a8.63,8.63,0,0,0,4.39-3.78,2.07,2.07,0,0,1,2.6-.89,1.8,1.8,0,0,1,.88,2.52,12.52,12.52,0,0,1-6.58,5.76,12.33,12.33,0,0,1-8.69-.3,1.8,1.8,0,0,1-.89-2.52,2.1,2.1,0,0,1,2.59-.94A8.38,8.38,0,0,0,126.35,143.39Zm47.22-8.3a8.63,8.63,0,0,1-5.52,1.77,8.35,8.35,0,0,1-5.19-2.36,2.09,2.09,0,0,0-2.75-.14A1.78,1.78,0,0,0,160,137a12.23,12.23,0,0,0,7.88,3.66,12.5,12.5,0,0,0,8.3-2.72,1.8,1.8,0,0,0,.18-2.67A2.08,2.08,0,0,0,173.57,135.09Z"/>
</svg>

export default Urchin