import React from 'react'

const Clover = props => <svg 
	width="261" 
	height="261" 
	viewBox="0 0 260.8 260.8"
	xmlns="http://www.w3.org/2000/svg"
  fill="currentColor"
  stroke="none"
  strokeLinecap="round" 
  strokeLinejoin="round"
  {...props}
>
<path fillRule="evenodd" d="M130.4,0a70.55,70.55,0,0,1,69.33,57.53,3.75,3.75,0,0,0,3,3,70.54,70.54,0,0,1,.16,138.82,3.76,3.76,0,0,0-3,3.05,70.54,70.54,0,0,1-139,0,3.76,3.76,0,0,0-3-3.05,70.54,70.54,0,0,1,.16-138.82,3.75,3.75,0,0,0,3-3A70.55,70.55,0,0,1,130.4,0Zm21.69,118.3a12,12,0,0,1,7.56-2.77,11.6,11.6,0,0,1,7.34,3,2.89,2.89,0,0,0,3.82,0,2.49,2.49,0,0,0,.07-3.71,17.08,17.08,0,0,0-11.15-4.64,17.4,17.4,0,0,0-11.37,4.27,2.5,2.5,0,0,0-.1,3.71A2.9,2.9,0,0,0,152.09,118.3ZM95.41,131a12,12,0,0,1,6.45-4.83,11.66,11.66,0,0,1,7.89.75,2.9,2.9,0,0,0,3.67-1.06,2.49,2.49,0,0,0-1-3.57,17,17,0,0,0-12-1.25,17.4,17.4,0,0,0-9.68,7.35,2.51,2.51,0,0,0,1,3.59A2.9,2.9,0,0,0,95.41,131Zm48.83,7.73a11.94,11.94,0,0,1-6.6,4.62,11.63,11.63,0,0,1-7.86-1,2.9,2.9,0,0,0-3.71.94,2.49,2.49,0,0,0,.89,3.6,17,17,0,0,0,12,1.64,17.38,17.38,0,0,0,9.91-7,2.51,2.51,0,0,0-.86-3.62A2.91,2.91,0,0,0,144.24,138.71Z"/>
</svg>

export default Clover