import React from 'react'

const Arrow = props => <svg 
	width="24" 
	height="24" 
	viewBox="0 0 24 24"
	xmlns="http://www.w3.org/2000/svg"
  fill="currentColor"
  stroke="none" 
  strokeLinecap="round" 
  strokeLinejoin="round"
  {...props}
>
<path d="M12.29,5.29a1,1,0,0,1,1.42,0l6,6a1,1,0,0,1,0,1.42l-6,6a1,1,0,0,1-1.42-1.42L16.59,13H5a1,1,0,0,1,0-2H16.59l-4.3-4.29A1,1,0,0,1,12.29,5.29Z"/>
</svg>

export default Arrow
