import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'
import { useTransition, animated, useSpringRef } from '@react-spring/web'

import Logo from 'components/svg/Logo'
import { Button, Hl, HugeType } from 'components/Styled'
import Clover from 'components/svg/Clover'
import { useTimeout } from 'components/utility/hooks'
import { SlideAnimation } from 'components/styled/animations'

import { Button1, Body, NoWrap } from 'components/Styled'
import Butterfly from 'components/svg/Butterfly'
import Calendar from 'components/svg/Calendar'
import Clock from 'components/svg/Clock'
import Star from 'components/svg/Star'
import Arrow from 'components/svg/Arrow'

const breathe = keyframes`
  0% {
    transform: scale(0.9);
  }

  25% {
    transform: scale(1);
  }

  80% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(0.9);
  }
`

const HomeWrapper = styled(SlideAnimation)`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  width: 100%;
  .header {
    padding: 6rem 3rem 0;
    display: flex;
    justify-content: center;
    position: absolute;
    width: 100%;
  }
  .footer {
    position: absolute;
    padding: 0 3rem 3rem;
    width: 100%;
    bottom: 0;
  }
`
const SceneWrapper = styled.div`
  position: absolute;
  top: 10.2rem;
  bottom: 6rem;
  width: 100%;
  .scene {
    backface-visibility: hidden;
    position: absolute;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    text-align: center;
    width: 100%;
  }
`
const Hl1 = styled(Hl)`
  margin-top: 2rem;
`
const Character1 = styled(Clover)`
  animation: ${breathe} 3s ease-out infinite normal;
  height: 26rem;
  width: 26rem;
  @media( max-width: 340px ){
    height: 22rem;
    width: 22rem;
  }
`
const Character2 = styled(Clover)`
  // animation: ${breathe} 3s ease-out infinite normal;
  height: 18rem;
  width: 18rem;
`

const Frame = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 4rem;
  width: 100%;
`
const Character = styled(Butterfly)`
  height: 24.4rem;
  width: 24.4rem;
  @media( max-width: 340px ){
    height: 16rem;
    width: 16rem;
  }
`
const Text = styled(Body)`
  height: 10rem;
  position: relative;
  width: 100%;
  > div {
    position: absolute;
    width: 100%;
  }
`
const Panel = styled.div`
  background: #fff;
  border-radius: 1rem;
  color: #000;
  padding: 3rem;
  max-width: 32.6rem;
  margin: 0 auto;
  @media( max-width: 340px ){
    padding: 2rem;
  }
`
const PanelBody = styled.p`
  font-size: 2.1rem;
  margin-bottom: 6.4rem;
  @media( max-width: 340px ){
    margin-bottom: 4rem;
  }
`
const IntakeButton = styled(Button)`
  margin-top: 1.6rem;
`
const Icon = styled.svg`
  margin-right: .5rem;
`
const CalendarIcon = Icon.withComponent(Calendar)
const ClockIcon = Icon.withComponent(Clock)
const StarIcon = Icon.withComponent(Star)
const ArrowIcon = styled(Arrow)`
  margin-left: .5rem;
`
const NextButton = styled(Button1)`
  transition: opacity .3s;
  margin: 0 auto;
  position: relative;
  ${ props => props.theme.hidden ? `opacity: 0;` : null}
  > div {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    position: absolute;
    width: 100%;
  }
`

const Scene1 = () => {

  return (
    <>
      <Character1 />
      <HugeType>Hello</HugeType>
    </>
  )
}


const slideFade = {
  keys: null,
  from: { opacity: 0, transform: 'translate3d(100%,0,0)' },
  enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
  leave: { opacity: 0, transform: 'translate3d(-50%,0,0)' },
}

const Index = () => {
  const [index, setIndex] = useState(0)

  useTimeout(() => {
    setIndex(1)
  }, index > 0 ? null : 5000)


  useEffect( () =>  {
    const onKeyDown = e => {
      e.preventDefault()
      if (e.key === 'ArrowLeft' ) {
        setIndex(val => Math.max( val-1, 0 ) )
      } else if (e.key === 'ArrowRight') {
        setIndex(val => Math.min( val+1, 4 ) )
      }
    }
    document.addEventListener( "keydown", onKeyDown)
    return () => document.removeEventListener( "keydown", onKeyDown)
  }, [index, setIndex])

  const nextClick = () => {
    if( index === 4 ) return
    setIndex(val => val+1)
  }

  
  const headerTransRef = useSpringRef()
  const headerTransitions = useTransition(index>1, {
    ref: headerTransRef,
    ...slideFade,
  })
  const textTransRef = useSpringRef()
  const textTransitions = useTransition(index===2, {
    ref: textTransRef,
    ...slideFade,
  })
  const footerTransRef = useSpringRef()
  const footerTransitions = useTransition(index > 0, {
    ref: footerTransRef,
    ...slideFade,
  })
  const buttonTransRef = useSpringRef()
  const buttonTransitions = useTransition(index < 2, {
    ref: buttonTransRef,
    ...slideFade,
  })
  const slideTransRef = useSpringRef()
  const slideTransitions = useTransition([0,1,4].indexOf(index), {
    ref: slideTransRef,
    ...slideFade,
  })
  useEffect(() => {
    footerTransRef.start()
    buttonTransRef.start()
    headerTransRef.start()
    textTransRef.start()
    slideTransRef.start()
  }, [index])

  return (
    <HomeWrapper>

      { headerTransitions((style, i) => {
        return i ? <animated.div className="header" style={style}><Logo /></animated.div> : null
      })}
      
      <SceneWrapper>
      { 
        slideTransitions((style, i) => {
          if( i === 0) return <animated.div className="scene" style={style}><Scene1 /></animated.div>
          if( i === 1 ) return (
            <animated.div className="scene" style={style}>
              <Character2 />
              <Hl1>Treat needle pain <br />with care.</Hl1>
            </animated.div>
          )
          if( i === -1 ) return (
            <animated.div className="scene" style={style}>
              <Frame>
                <Character />
              </Frame>
              <Text>
                { textTransitions((style, i) => {
                  return i ? <animated.div style={style}><p>We’ll be your <br />needle care sidekick.</p></animated.div> 
                    : 
                    <animated.div style={style}><p>Needles make a lot of kids and <NoWrap>adults nervous.</NoWrap></p><p>We’re here to help.</p></animated.div> 
                  })
                }
              </Text>
            </animated.div>
          )
          if( i === 2 ) return (
            <animated.div className="scene" style={style}>
              <Panel>
                <PanelBody>Where are you in the appointment process?</PanelBody>
                <IntakeButton to={`/prep`}><CalendarIcon />I’m preparing</IntakeButton>
                <IntakeButton to={`/during`}><ClockIcon />It’s go time</IntakeButton>
                <IntakeButton to={`/after`}><StarIcon />It’s all done</IntakeButton>
              </Panel>
            </animated.div>
          )
        })
      }
      </SceneWrapper>

      { 
        footerTransitions((style, i) => {
        return i ?
          <animated.div className="footer" style={style}>
            <NextButton
              theme={{ hidden: index === 4 }}
              onClick={nextClick} 
              type="button"
            >
              {buttonTransitions((style, i) => {
                return i ? <animated.div style={style}>Get Started</animated.div> : <animated.div style={style}>Next <ArrowIcon /></animated.div>
              })}
            </NextButton>
          </animated.div>
            : 
            null
          }
        )
      }
    </HomeWrapper>
  )
}

export default Index
