import React from 'react'

const SadPinto = props => <svg 
	width="264" 
	height="250" 
	viewBox="0 0 264 250"
	xmlns="http://www.w3.org/2000/svg"
  fill="currentColor"
  stroke="none"
  strokeLinecap="round" 
  strokeLinejoin="round"
  {...props}
>
	<path fillRule="evenodd" d="M25.9,63.09,97.19,12.74a60.56,60.56,0,0,1,69.58,0l71.32,50.35a57.44,57.44,0,0,1,21.43,28.67,55.85,55.85,0,0,1,0,35.43l-27.25,81.46a57.45,57.45,0,0,1-21.47,28.69,60.21,60.21,0,0,1-34.73,10.91H87.91A60.23,60.23,0,0,1,53.2,237.3a57.58,57.58,0,0,1-21.5-28.65L4.46,127.19a55.89,55.89,0,0,1,0-35.42A57.61,57.61,0,0,1,25.9,63.09Zm103.83,81.83a10.36,10.36,0,0,1,6.12-3.38,10.14,10.14,0,0,1,6.7,1.58,2.52,2.52,0,0,0,3.29-.47,2.16,2.16,0,0,0-.43-3.19,14.84,14.84,0,0,0-10.19-2.5,15.15,15.15,0,0,0-9.2,5.17,2.17,2.17,0,0,0,.41,3.19A2.52,2.52,0,0,0,129.73,144.92ZM105.62,125.7a10.37,10.37,0,0,0,5.3-4.57,2.52,2.52,0,0,1,3.15-1.06,2.16,2.16,0,0,1,1.05,3,15.06,15.06,0,0,1-8,6.94,14.8,14.8,0,0,1-10.48-.36,2.17,2.17,0,0,1-1.08-3,2.54,2.54,0,0,1,3.13-1.14A10.1,10.1,0,0,0,105.62,125.7Zm57-10a10.37,10.37,0,0,1-6.66,2.14,10.08,10.08,0,0,1-6.27-2.85,2.53,2.53,0,0,0-3.32-.17,2.16,2.16,0,0,0-.19,3.21,14.82,14.82,0,0,0,9.51,4.43,15.11,15.11,0,0,0,10-3.3,2.16,2.16,0,0,0,.21-3.21A2.51,2.51,0,0,0,162.62,115.68Z"/>
</svg>

export default SadPinto