import styled from '@emotion/styled'

export const SlideAnimation = styled.div`
  .slideLeftFade-enter .slideItem {
    opacity: 0;
    transform: translateX(100%);
  }
  .slideLeftFade-enter-active .slideItem {
    opacity: 1;
    transform: translateX(0%);
  }
  .slideLeftFade-exit .slideItem {
    opacity: 1;
    transform: translateX(0%);
  }
  .slideLeftFade-exit-active .slideItem {
    opacity: 0;
    transform: translateX(-100%);
  }
  .slideLeftFade-enter-active .slideItem,
  .slideLeftFade-exit-active .slideItem {
    transition: opacity 300ms linear, transform 300ms linear;
  }


  .fade-enter .fadeItem {
    opacity: 0;
  }
  .fade-enter-active .fadeItem {
    opacity: 1;
  }
  .fade-exit .fadeItem {
    opacity: 1;
  }
  .fade-exit-active .fadeItem {
    opacity: 0;
  }
  .fade-enter-active .fadeItem,
  .fade-exit-active .fadeItem {
    transition: opacity 300ms linear;
  }
`