import styled from '@emotion/styled'
import { Link } from 'react-router-dom'

import Arrow from 'components/svg/Arrow'

export const Wrapper = styled.div`
  height: 100%;
  padding: 12rem 3rem 6rem;
  position: relative;
  width: 100%;
  @media( max-width: 340px ){
    padding-left: 2rem;
    padding-right: 2rem;
  }
`
export const ChapterWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
export const Hl = styled.h1`
  font-size: 4.2rem;
  font-weight: 300;
  line-height: 120%;
  @media( max-width: 340px ){
    font-size: 3.2rem;
  }
`
export const LargeType = styled.p`
  font-size: 4.2rem;
  line-height: 1.2;
  font-weight: 300;
  @media( max-width: 340px ){
    font-size: 3.2rem;
  }
`
export const HugeType = styled.p`
  font-size: 12.6rem;
  line-height: 1;
  font-weight: 300;
  @media( max-width: 340px ){
    font-size: 10rem;
  }
`
export const ChapterHl = styled.h2`
  font-size: 2.1rem;
  font-weight: 400;
  line-height: 120%;
  margin: 0 0 3.2rem;
  @media( max-width: 340px ){
    margin: 0 0 1rem;
  }
`
export const ChapterSpacer = styled.div`
  height: 7.3rem;
  width: 100%;
  @media( max-width: 340px ){
    height: 5.3rem;
  }
`
export const Button1 = styled.button`
  align-items: center;
  background: 0;
  border-radius: 3rem;
  border: 2px solid currentColor;
  display: flex;
  font-size: 2rem;
  height: 6rem;
  justify-content: center;
  line-height: 1;
  min-width: 0;
  max-width: 34em;
  outline: 0;
  padding: 1rem 2rem;
  width: 100%;
`
export const Button = Button1.withComponent(Link)
export const Body = styled.div`
  font-size: 2.1rem;
  ul,
  ol,
  p {
    margin: .5em 0;
  }
  a {
    text-decoration: underline;
  }
`
export const NoWrap = styled.span`
  white-space: nowrap;
`

const StepsWrapper = styled.div`
  width: 100%;
`
const Step = styled(Link)`
  background: ${props => props.theme.bg};
  border-radius: 1rem;
  color: ${props => props.theme.color};
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 1.6rem;
  min-height: 10rem;
  padding: 1rem 2.4rem;
  text-align: left;
  text-align: left;
  ${ props => props.theme.disabled ? `opacity: .5;` : null}
  @media( max-width: 340px ){
    min-height: 8rem;
    padding: 1rem 2rem;
  }
`
const MainRow = styled.div`
  display: flex;
  align-items: center;
`
const StepLabel = styled.p`
  font-size: 1.4rem;
  margin-bottom: 1rem;
  font-weight: 500;
`
const StepText = styled.p`
  font-size: 2.1rem;
  margin-left: 1rem;
  flex: 1 0 0;
  min-width: 0;
`

export const Steps = ({ showLabel, options, theme, ...props }) => {
  return (
    <StepsWrapper {...props}>
      {
        options.map( (el, i) => (
          <Step key={i} className="step" to={el.to} theme={{...theme, disabled: el.disabled}}>
            <div>
              { showLabel ? <StepLabel>Step {i+1}</StepLabel> : null }
              <MainRow>
                <Arrow />
                <StepText>{el.label}</StepText>
              </MainRow>
            </div>
          </Step>
        ))
      }
    </StepsWrapper>
  )
}
