import React from 'react'

const Logo = props => <svg 
	width="200" 
	height="42" 
	viewBox="0 0 200 41.67"
	xmlns="http://www.w3.org/2000/svg"
  fill="currentColor"
  stroke="none"
  {...props}
>
	<path d="M20.83,0A20.84,20.84,0,1,0,41.67,20.83,20.84,20.84,0,0,0,20.83,0Zm0,38.33a17.5,17.5,0,1,1,17.5-17.5A17.5,17.5,0,0,1,20.83,38.33Z"/>
	<path d="M22.92,10.29a2.21,2.21,0,1,1-2.21-2.2A2.21,2.21,0,0,1,22.92,10.29Z"/>
	<circle cx="27.89" cy="13.27" r="2.21" transform="translate(-1.1 2.61) rotate(-5.25)"/>
	<circle cx="30.87" cy="20.96" r="2.21"/>
	<path d="M50,11.18c0,.12,0,.17.17.17H57c.07,0,.12.05.17.14V33.16c0,.12.07.17.19.17h3c.12,0,.18,0,.18-.17V11.54c0-.12,0-.19.17-.19h6.85c.13,0,.2,0,.2-.17V8.51c0-.12-.07-.18-.2-.18H50.17c-.12,0-.17.06-.17.18Z"/>
	<path d="M72.18,33.16c0,.12,0,.17.17.17h3c.12,0,.19,0,.19-.17v-11c0-.09.06-.14.18-.14H87c.12,0,.17.05.17.17v11c0,.12.07.17.19.17h3c.13,0,.18,0,.18-.17V8.51c0-.12,0-.18-.18-.18h-3a.17.17,0,0,0-.19.18V18.77c0,.12,0,.19-.17.19H75.74a.17.17,0,0,1-.18-.19V8.51a.17.17,0,0,0-.19-.18h-3c-.12,0-.17.06-.17.18Z"/>
	<rect x="96.99" y="8.33" width="3.38" height="25" rx="0.17"/>
	<path d="M106.81,33.16c0,.12.06.17.18.17h3c.12,0,.19,0,.19-.17V11.89a.18.18,0,0,1,.35,0l6.26,21.31c0,.12.12.17.28.17h4.82c.16,0,.26,0,.3-.17l6.25-21.31a.18.18,0,0,1,.36,0V33.16c0,.12.05.17.17.17h3c.12,0,.18,0,.18-.17V8.51a.16.16,0,0,0-.18-.18h-5.76a.26.26,0,0,0-.24.18l-6.34,21.3a.18.18,0,0,1-.34,0L113,8.51a.26.26,0,0,0-.24-.18H107a.16.16,0,0,0-.18.18Z"/>
	<path d="M156.57,22.69a5.13,5.13,0,0,0-2.32-2.24.19.19,0,0,1,0-.36,4.46,4.46,0,0,0,2-1.74,5.24,5.24,0,0,0,.83-2.91,7.32,7.32,0,0,0-1.84-5,6.07,6.07,0,0,0-4.7-2.07H138.68a.17.17,0,0,0-.19.18V33.16c0,.12.07.17.19.17h11.64a7.25,7.25,0,0,0,5.24-2,7.06,7.06,0,0,0,1-8.67Zm-14.7-11.2c0-.12,0-.17.18-.17h7.79a3.47,3.47,0,0,1,2.83,1.21,4.42,4.42,0,0,1,1,2.91,3.09,3.09,0,0,1-1.18,2.46A4.11,4.11,0,0,1,149.84,19h-7.79c-.13,0-.18-.07-.18-.19ZM153,29.09a4.39,4.39,0,0,1-3.16,1.23h-7.79c-.13,0-.18-.06-.18-.18v-8c0-.12,0-.17.18-.17h8A3.88,3.88,0,0,1,153,23.3a4.17,4.17,0,0,1,1.25,2.93A3.9,3.9,0,0,1,153,29.09Z"/>
	<path d="M163,33.16c0,.12.06.17.19.17H179c.12,0,.17,0,.17-.17V30.49c0-.12,0-.17-.17-.17H166.55c-.12,0-.17-.06-.17-.18V8.51c0-.12,0-.18-.17-.18h-3c-.13,0-.19.06-.19.18Z"/>
	<path d="M182.72,33.33c-.13,0-.18,0-.18-.17V8.51c0-.12.05-.18.18-.18h17.09a.17.17,0,0,1,.19.18v2.67c0,.12-.07.17-.19.17H186.1c-.12,0-.17.07-.17.19v7.23c0,.12,0,.19.17.19h12.25c.12,0,.19.05.19.17v2.66a.17.17,0,0,1-.19.19H186.1c-.12,0-.17.05-.17.17v8c0,.12,0,.18.17.18h13.71c.12,0,.19.05.19.17v2.67c0,.12-.07.17-.19.17Z"/>
</svg>

export default Logo
