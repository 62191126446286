import React from 'react'

const Calendar = props => <svg 
	width="24" 
	height="24" 
	viewBox="0 0 24 24"
	xmlns="http://www.w3.org/2000/svg"
  fill="currentColor"
  stroke="none" 
  strokeLinecap="round" 
  strokeLinejoin="round"
  {...props}
>
<path d="M9,2.5a1,1,0,0,1,1,1v1h4v-1a1,1,0,0,1,2,0v1h3a2,2,0,0,1,2,2v13a2,2,0,0,1-2,2H5a2,2,0,0,1-2-2V6.5a2,2,0,0,1,2-2H8v-1A1,1,0,0,1,9,2.5Zm-1,4H5v3H19v-3H16v1a1,1,0,0,1-2,0v-1H10v1a1,1,0,0,1-2,0Zm11,5H5v8H19Z"/>
</svg>

export default Calendar
