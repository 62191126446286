import React from 'react'

const ChevronRight = props => <svg 
	width="24" 
	height="24" 
	viewBox="0 0 24 24"
	xmlns="http://www.w3.org/2000/svg"
  fill="currentColor"
  stroke="none" 
  strokeLinecap="round" 
  strokeLinejoin="round"
  {...props}
>
<path d="M11.29,5.29a1,1,0,0,1,1.42,0l6,6a1,1,0,0,1,0,1.42l-6,6a1,1,0,0,1-1.42-1.42L16.59,12l-5.3-5.29A1,1,0,0,1,11.29,5.29Zm-6,0a1,1,0,0,1,1.42,0l6,6a1,1,0,0,1,0,1.42l-6,6a1,1,0,0,1-1.42-1.42L10.59,12,5.29,6.71A1,1,0,0,1,5.29,5.29Z"/>
</svg>

export default ChevronRight
