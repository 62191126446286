import React from 'react'

const Butterfly = props => <svg 
	width="246" 
	height="246" 
	viewBox="0 0 246.31 246.11"
	xmlns="http://www.w3.org/2000/svg"
  fill="currentColor"
  stroke="none"
  strokeLinecap="round" 
  strokeLinejoin="round"
  {...props}
>
<path fillRule="evenodd" d="M28.76,242.83C12.83,239.1,3.15,227.21.58,208.25c-1.25-9.2,0-31.49,2.46-43.11,4.94-23.6,18.08-38.56,37.28-42.42a.85.85,0,0,0,0-1.66l-.13,0c-15-3.4-25.07-11.61-31.88-26.13C3.1,83.72,0,66.3,0,47.76c0-17.12,3.36-28.5,10.84-36.57a34,34,0,0,1,15-9.4C29.58.58,31.66.3,38.51.07A84.54,84.54,0,0,1,63.92,2.73a184.77,184.77,0,0,1,39.13,14.81,58.3,58.3,0,0,0,6.31,2.68,1.67,1.67,0,0,0,2-.7c1.78-2.86,6.56-10.52,9.93-15.9a1.7,1.7,0,0,1,2.84-.09c3.77,5.31,9,13.2,10.91,16.07a1.66,1.66,0,0,0,2,.66,62.46,62.46,0,0,0,6.38-2.73,185.19,185.19,0,0,1,39.12-14.8A84.54,84.54,0,0,1,207.89.07c6.85.23,8.93.51,12.65,1.72a34,34,0,0,1,15,9.39c8.22,8.87,11.39,21.3,10.66,41.88-1.44,40.79-14.5,62.67-40.69,68.15a.71.71,0,0,0,0,1.39l.55.11q32.8,6.71,39,52.37c1.22,8.93,1.64,24.9.84,31.72-2.8,23.94-15.15,36-37.86,37.15-17.25.83-40-5.33-64.12-17.41-2.89-1.44-5.51-2.69-6.79-3.25a1.08,1.08,0,0,0-1.33.4c-1,1.44-3.47,4.89-6,8.89-2,3.14-3.83,6.1-5,8a1.67,1.67,0,0,1-2.78.1c-1.26-1.71-3.18-4.43-5.19-7.64-3-4.77-5.67-8.79-6.52-9.79a.46.46,0,0,0-.58-.1c-1,.43-4,1.8-7.26,3.4-16.55,8.29-33,14-48,16.56C48.09,244.21,34,244.06,28.76,242.83ZM140.4,113.43a10,10,0,0,1,6.56-1.57,9.75,9.75,0,0,1,5.82,3.16,2,2,0,0,0,2.66.35,1.72,1.72,0,0,0,.35-2.53,13.57,13.57,0,0,0-8.35-4.62,13.88,13.88,0,0,0-9.34,2.3,1.71,1.71,0,0,0-.34,2.53A2,2,0,0,0,140.4,113.43Zm-46.87,4.93A10,10,0,0,1,99.36,115a9.76,9.76,0,0,1,6.49,1.37,2,2,0,0,0,2.64-.42,1.71,1.71,0,0,0-.38-2.52,13.59,13.59,0,0,0-9.34-2.06,13.83,13.83,0,0,0-8.28,4.86,1.71,1.71,0,0,0,.39,2.52A2,2,0,0,0,93.53,118.36Zm38.56,9.51a10,10,0,0,1-5.93,3.17,9.74,9.74,0,0,1-6.45-1.58,2,2,0,0,0-2.65.34,1.7,1.7,0,0,0,.3,2.53,13.66,13.66,0,0,0,9.26,2.36,13.82,13.82,0,0,0,8.44-4.59,1.72,1.72,0,0,0-.31-2.54A2,2,0,0,0,132.09,127.87Z"/>
</svg>

export default Butterfly
