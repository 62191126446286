import React, { useState, useEffect, useRef } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import styled from '@emotion/styled'
import Helmet from 'react-helmet'
import Flickity from 'react-flickity-component'
import Lottie from "lottie-react"

import { useWindowDimensions, useTimeout } from 'components/utility/hooks'
import { Button1 as Button } from 'components/Styled'
import Close from 'components/svg/Close'
import Arrow from 'components/svg/Arrow'
import Check from 'components/svg/Check'
import ChevronRight from 'components/svg/ChevronRight'
import chaptersData from 'components/chaptersData'
import confettiAnimation from "animation/confetti.json"

const ProgressWrapper = styled.div`
	padding: 6rem 3rem 1rem;
	position: relative;
	width: 100%;
  @media( max-width: 340px ){
    padding-left: 2rem;
    padding-right: 2rem;
  }
`
const Row = styled.div`
	align-items: center;
	justify-content: center;
	display: flex;
`
const CloseIcon = styled(Close)`
	margin-right: 2.4rem;	
	cursor: pointer;
`
const Track = styled.div`
	max-width: 24.6rem;
	height: .4rem;
	overflow: hidden;
	border-radius: .2rem;
	flex: 1 0 0;
	position: relative;
`
const Underlay = styled.div`
	background: currentColor;
	bottom: 0;
	left: 0;
	opacity: .3;
	position: absolute;
	right: 0;
	top: 0;
`
const Bar = styled.div`
	background: currentColor;
	height: 100%;
	left: 0;
	position: absolute;
	transition: transform .3s;
	width: 100%;
	transform: translateX( ${props => (100 - props.theme.progress) * -1 }%);
`
const Title = styled.p`
	font-size: 1.4rem;
	font-weight: 500;
	line-height: 1;
	text-align: center;
`
const Spacer = styled.div`
	width: 4.8rem;
	height: 1rem;
`
const Chevron = styled(ChevronRight)`
	margin-left: 2.4rem;	
	cursor: pointer;
`
const Progress = ({ value, possible, title, dismiss, advance }) => {

	return (
		<ProgressWrapper>
			<Row>
				<CloseIcon onClick={dismiss} />
				<Track>
					<Underlay />
					<Bar theme={{ progress: (value / possible) * 100 }} />
				</Track>
				{ value !== possible ? <Chevron onClick={advance} /> : <Spacer /> }
			</Row>
			<Title>{title}</Title>
		</ProgressWrapper>
	)
}

const Footer = styled.div`
	padding: 1rem 3rem 2rem;
	height: 9rem;
	position: relative;
	display: flex;
	width: 100%;
  @media( max-width: 340px ){
    padding-left: 2rem;
    padding-right: 2rem;
  }
`
const ChapterButton = styled(Button)`
	margin: 0 auto;
`
const LeftArrowIcon = styled(Arrow)`
	transform: scale(-1);
	margin-right: .5rem;
`
const ArrowIcon = styled(Arrow)`
	margin-left: .5rem;
`
const CheckIcon = styled(Check)`
	margin-left: .5rem;
`



const ChapterWrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0;
	z-index: 400;
  background: ${props => props.theme.bg };
  color: ${props => props.theme.color };
  height: 100%;
  position: relative;
  width: 100%;
`
const ContentWrapper = styled.div`
	flex: 1 0 0;
	min-height: 0;
	overflow-y: auto;
`
const Content = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	font-size: 2.1rem;
	padding: 2rem 3rem;
	height: 100%;
`
const ConfettiLottie = styled(Lottie)`
	bottom: 0;
	left: 0;
	position: absolute;
	right: 0;
	pointer-events: none;
	overflow: hidden;
	top: 0;
	svg {
		height: 100%;
		aspect-ratio: 1;
		display: block;
		width: auto !important;
	}
	path {
		fill: ${props => props.theme.color} !important;
	}
`

const Chapter = () => {
	// const [paused, setPaused] = useState()
	const { chapter, page } = useParams()
	const history = useHistory()

	const pageNumber = page ? parseInt(page) : 1
	
	const data = chaptersData.find( el => el.id === chapter )

	const { steps, theme, title, path, pageTitle } = data || {}
	const stepData = steps?.[pageNumber-1]
	const { cta, content, duration, linkTo } = stepData || {}

	const advance = () => {
		if( linkTo ){
			return history.push(linkTo) 
		}else{
			return history.push(`${path}/${chapter}/${pageNumber+1}`)
		}
	}

	useEffect( () =>  {
		const onKeyDown = e => {
			if (e.key === 'ArrowLeft' || e.key === 'ArrowRight' ) {
	      e.preventDefault()
	    }
	    if (e.key === 'ArrowLeft' && pageNumber > 1 ) {
	    	history.push(`${path}/${chapter}/${pageNumber-1}`)
	    } else if (e.key === 'ArrowRight' && pageNumber < steps.length) {
	    	advance()
	    } else if (e.key === 'Escape') {
	    	history.push(path)
	    }
		}
		document.addEventListener( "keydown", onKeyDown)
		return () => document.removeEventListener( "keydown", onKeyDown)
	}, [history, pageNumber])

	useTimeout(() => {
    advance()
  }, duration )

	const dismiss = () => {
		history.push(path)
	}

	const ctaClick = () => {
		switch(cta){
			case "BEGIN":
			case "NEXT":
			case "DID_IT":
				return advance()
			case "FINISH":
				return history.push(path)
			default:
				return
		}
	}

	let ctaText = "Next"
	switch(cta){
		case "ROTATOR":
			ctaText = "Next"
			break
		case "BEGIN":
			ctaText = "Begin"
			break
		case "PAUSE":
			ctaText = "Pause Guide"
			break
		case "DID_IT":
			ctaText = "I did it"
			break
		case "FINISH":
			ctaText = "Finish"
			break
		default:
			break
	}

	if( !stepData ){ 
		history.push(`/lost`) 
		return null
	}
	
	const contentClick = () => {
		if( ["NEXT", "BEGIN", "DID_IT", "CONFETTI"].indexOf(cta) > -1 ) advance()
	}

	return (
		<ChapterWrapper 
			theme={theme}
		>
      <Helmet>
        <title>Thimble | {pageTitle}</title>
      </Helmet>
		{
			["CONFETTI"].indexOf(cta) === -1 ?
			<Progress
				value={pageNumber}
				possible={steps?.length}
				title={title}
				dismiss={dismiss}
				advance={advance}
			/>
			: null 
		}
			<ContentWrapper onClick={contentClick}>
				{ content }
			</ContentWrapper>

			{
				cta === "CONFETTI" ?
					<ConfettiLottie animationData={confettiAnimation} loop theme={theme} />
				: null
			}

			{
				["NULL", "CONFETTI"].indexOf(cta) === -1 ?
				<Footer>
					<ChapterButton 
						onClick={ctaClick} 
						type="button"
					>
						{ ctaText }
						{ ctaText === "Finish" ? <CheckIcon /> : null }
						{ ctaText === "Next" ? <ArrowIcon /> : null }
					</ChapterButton>
				</Footer>
				: null
			}
		</ChapterWrapper>
	)
}



const CarouselWrapper = styled.div`
	margin: 0 -3rem;
	align-self: stretch;
`
const Carousel = styled(Flickity)`
	height: 40rem;
	width: 100%;
  @media( max-width: 340px ){
    height: 34rem;
  }
`
const RotatorContent = styled(Content)`
	justify-content: center;
`
const Slide = styled.div`
	background: #fff;
	border-radius: 1rem;
	color: #000;
	display: flex;
	flex-direction: column;
	height: 40rem;
	justify-content: center;
	margin-right: .8rem;
	padding: 2rem;
	width: ${props => props.theme.width}rem;
	&.introSlide {
		background: none;
		color: inherit;
		padding: 0;
	}
  @media( max-width: 340px ){
    height: 34rem;
  }
`
const RotatorButton = styled(Button)`
	& + & {
		margin-left: .8rem;
	}
`

const Rotator = () => {

	const { chapter } = useParams()
	const history = useHistory()
  const { width } = useWindowDimensions()
  const [index, setIndex] = useState(0)
  const flkty = useRef()
	
	const data = chaptersData.find( el => el.id === chapter )

	const { steps, theme, title, pageTitle, backPath, finishPath, dismissPath } = data 

	useEffect( () =>  {
		const onKeyDown = e => {
			if (e.key === 'ArrowLeft' || e.key === 'ArrowRight' ) {
	      e.preventDefault()
	    }
	    if (e.key === 'ArrowLeft') {
	    	if( index > 0 ){
					flkty.current.previous()
				}else {
					history.push(backPath)
				}
	    } else if (e.key === 'ArrowRight') {
				if( index < steps.length - 1 ){
					flkty.current.next()
				}else {
					history.push(finishPath)
				}
	    } else if (e.key === 'Escape') {
	    	history.push(dismissPath)
	    }
		}
		document.addEventListener( "keydown", onKeyDown)
		return () => document.removeEventListener( "keydown", onKeyDown)
	}, [history, index])

	const slideWidth = (Math.min( width, 400) - 60) /10

	const dismiss = () => {
		history.push(dismissPath)
	}

	const backClick = () => {
		if( index > 0 ){
			flkty.current.previous()
		}else {
			history.push(backPath)
		}
	}
	const nextClick = () => {
		if( index < steps.length - 1 ){
			flkty.current.next()
		}else {
			history.push(finishPath)
		}
	}

	if( !data ){ 
		history.push(`/lost`) 
		return null
	}

	return (
		<ChapterWrapper 
			theme={theme}
		>
      <Helmet>
        <title>Thimble | {pageTitle}</title>
      </Helmet>

			<Progress
				value={index+1}
				possible={steps?.length}
				title={title}
				dismiss={dismiss}
				advance={nextClick}
			/>

			<ContentWrapper>
				<RotatorContent>
					<CarouselWrapper>
						<Carousel
							flickityRef={c => {
								flkty.current = c
								flkty.current?.on('change', () => {
									setIndex(flkty.current.selectedIndex)
						    })
							}}
				      options={{
				      	pageDots: false,
				      	prevNextButtons: false,
				      }}
				    >
				    	{
				    		steps.map( (el, i) => <Slide className={el.className} theme={{ width: slideWidth }} key={i}>{el.content}</Slide> )
				    	}
				    </Carousel>
				  </CarouselWrapper>
				</RotatorContent>
			</ContentWrapper>

			<Footer>
				<RotatorButton 
					onClick={backClick} 
					type="button"
				>
					<LeftArrowIcon />
					Back
				</RotatorButton>
				<RotatorButton 
					onClick={nextClick} 
					type="button"
				>
					{ index < steps.length -1 ? "Next" : "Finish" }
					{ index < steps.length -1 ? <ArrowIcon /> : <CheckIcon /> }
				</RotatorButton>
			</Footer>
		</ChapterWrapper>
	)
}


const Switch = () => {
	const { chapter } = useParams()
	
	const data = chaptersData.find( el => el.id === chapter )

	switch( data?.type ){
		case "ROTATOR":
			return <Rotator />
		default:
			return <Chapter />
	}

}

export default Switch

