import React from 'react'

const Pentagon = props => <svg 
	width="264" 
	height="249" 
	viewBox="0 0 264 249"
	xmlns="http://www.w3.org/2000/svg"
  fill="#fff"
  stroke="none" 
  strokeLinecap="round" 
  strokeLinejoin="round"
  {...props}
>
	<path d="M96.77,11.1,24.6,62a58.21,58.21,0,0,0-21.67,29,56.45,56.45,0,0,0,0,35.78L30.47,209a58.25,58.25,0,0,0,21.76,28.95A61.23,61.23,0,0,0,87.36,249H176.6a61,61,0,0,0,35.16-11,58,58,0,0,0,21.73-29l27.58-82.28a56.2,56.2,0,0,0,0-35.79A58,58,0,0,0,239.39,62L167.2,11.1a61.41,61.41,0,0,0-70.43,0Z"/>
</svg>

export default Pentagon
