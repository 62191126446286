import React from 'react'

const Cookie = props => <svg 
	width="326" 
	height="326" 
	viewBox="0 0 326 326"
	xmlns="http://www.w3.org/2000/svg"
  fill="currentColor"
  stroke="none"
  strokeLinecap="round" 
  strokeLinejoin="round"
  {...props}
>
<path fillRule="evenodd" d="M320.31,149.5a18.85,18.85,0,0,1-3.53-22.27,18.86,18.86,0,0,0-8.34-25.68A18.86,18.86,0,0,1,298.2,81.46a18.86,18.86,0,0,0-15.87-21.85,18.86,18.86,0,0,1-15.95-15.94A18.85,18.85,0,0,0,244.54,27.8a18.86,18.86,0,0,1-20.09-10.24,18.86,18.86,0,0,0-25.68-8.34A18.85,18.85,0,0,1,176.5,5.69a18.85,18.85,0,0,0-27,0,18.85,18.85,0,0,1-22.27,3.53,18.86,18.86,0,0,0-25.68,8.34A18.86,18.86,0,0,1,81.46,27.8,18.86,18.86,0,0,0,59.61,43.67,18.84,18.84,0,0,1,43.67,59.61,18.86,18.86,0,0,0,27.8,81.46a18.86,18.86,0,0,1-10.24,20.09,18.86,18.86,0,0,0-8.34,25.68A18.85,18.85,0,0,1,5.69,149.5a18.85,18.85,0,0,0,0,27,18.85,18.85,0,0,1,3.53,22.27,18.86,18.86,0,0,0,8.34,25.68A18.86,18.86,0,0,1,27.8,244.54a18.85,18.85,0,0,0,15.87,21.84,18.86,18.86,0,0,1,15.94,15.95A18.86,18.86,0,0,0,81.46,298.2a18.86,18.86,0,0,1,20.09,10.24,18.86,18.86,0,0,0,25.68,8.34,18.85,18.85,0,0,1,22.27,3.53,18.85,18.85,0,0,0,27,0,18.85,18.85,0,0,1,22.27-3.53,18.86,18.86,0,0,0,25.68-8.34,18.86,18.86,0,0,1,20.09-10.24,18.85,18.85,0,0,0,21.84-15.87,18.88,18.88,0,0,1,15.95-15.95,18.85,18.85,0,0,0,15.87-21.84,18.86,18.86,0,0,1,10.24-20.09,18.86,18.86,0,0,0,8.34-25.68,18.85,18.85,0,0,1,3.53-22.27A18.85,18.85,0,0,0,320.31,149.5Zm-173,13.81a2.69,2.69,0,0,1-3.38,1.1,10.77,10.77,0,0,0-7.35-.46,11.09,11.09,0,0,0-5.84,4.68,2.7,2.7,0,0,1-3.41,1,2.33,2.33,0,0,1-1-3.3,16.18,16.18,0,0,1,8.77-7.12,15.88,15.88,0,0,1,11.19.79A2.32,2.32,0,0,1,147.29,163.31Zm33.35,13.44a16.07,16.07,0,0,1-9,6.83,15.8,15.8,0,0,1-11.16-1.15,2.32,2.32,0,0,1-.92-3.33,2.7,2.7,0,0,1,3.41-1,10.8,10.8,0,0,0,7.33.7,11.06,11.06,0,0,0,6-4.49,2.7,2.7,0,0,1,3.44-.9A2.32,2.32,0,0,1,180.64,176.75Zm19.73-21.94a2.71,2.71,0,0,1-3.56.08,10.73,10.73,0,0,0-6.9-2.55,11.09,11.09,0,0,0-6.94,2.81,2.69,2.69,0,0,1-3.55,0,2.32,2.32,0,0,1,0-3.45,16.17,16.17,0,0,1,10.44-4.31,15.88,15.88,0,0,1,10.49,4A2.32,2.32,0,0,1,200.37,154.81Z"/>
</svg>

export default Cookie