import React from 'react'
import styled from '@emotion/styled'
import Rive from 'rive-react'

import { Wrapper, LargeType, Button } from 'components/Styled'


const NotFoundWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  padding: 3rem;
`
const Hl = styled(LargeType)`
  margin-bottom: 4rem;
`
const StyledRive = styled(Rive)`
  height: 28rem;
  width: 28rem;
  position: relative;
  margin: 0 auto;
`
const Content = styled.div`
  text-align: center;
  margin: auto 0;
`

const NotFound = () => {
  return (
    <NotFoundWrapper>
      <Content>
        <StyledRive key="wiggle" src="/_media/animation/wiggle.riv" />
        <Hl>Uh oh! Looks like you’re lost.</Hl>
      </Content>
      
      <Button to={`/prep`}>Start Over</Button>
    </NotFoundWrapper>
  )
}

export default NotFound
