import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Helmet from 'react-helmet'
import styled from '@emotion/styled'

const Wrapper = styled.div`
	background: var(--lake);
	align-items: center;
	display: flex;
	height: 100vh;
	justify-content: center;
	padding: 2rem;
	width: 100vw;
`
const Form = styled.form`
	max-width: 30rem;
	width: 100%;
`
const Message = styled.p`
	font-size: 1.4rem;
	margin-bottom: 1rem;
	color: "var(--sky)";
`
const Input = styled.input`
	background: var(--sky);
	border-radius: 1rem;
	border: 0;
	color: var(--lake);
	height: 6rem;
	margin-bottom: 2rem;
	outline: 0;
	padding: 0 2rem;
	font-size: 2.1rem;
	width: 100%;
`
const Footer = styled.div`
	display: flex;
	justify-content: flex-end;
`
const Button = styled.button`
	appearance: none;
	background: 0;
	border-radius: 1.5rem;
  border: 2px solid var(--sky);
  color: var(--sky);
  font-size: 1.4rem;
  height: 4.8rem;
  position: absolute;
  width: 10.8rem;
`


const Login = () => {
	const [value, setValue] = useState()
	const [tried, setTried] = useState()
	const history = useHistory()

	const onSubmit = (e) => {
		e.preventDefault()
		if( value?.toUpperCase() === "HEALTH" ){
			localStorage.setItem( "thimble_auth", true )
			history.push("/")
		}else{
			setTried(true)
		}
	}

	return (
		<Wrapper>
			<Helmet>
        <title>Thimble | Login</title>
      </Helmet>
			<Form onSubmit={onSubmit}>
				{ tried ? <Message>Incorrect password. Please try again.</Message> : null }
				<Input 
					type="password"
					value={value || ""}
					onChange={e => setValue(e.currentTarget.value)}
					placeholder="Password"
				/>
				<Footer>
					<Button type="submit">Submit</Button>
				</Footer>
			</Form>
		</Wrapper>
	)
}

export default Login