import React from 'react'

const Clock = props => <svg 
	width="24" 
	height="24" 
	viewBox="0 0 24 24"
	xmlns="http://www.w3.org/2000/svg"
  fill="currentColor"
  stroke="none" 
  strokeLinecap="round" 
  strokeLinejoin="round"
  {...props}
>
<path d="M12,4a8,8,0,1,0,8,8A8,8,0,0,0,12,4ZM2,12A10,10,0,1,1,12,22,10,10,0,0,1,2,12ZM12,6a1,1,0,0,1,1,1v4.59l2.71,2.7a1,1,0,0,1-1.42,1.42l-3-3A1,1,0,0,1,11,12V7A1,1,0,0,1,12,6Z"/>
</svg>

export default Clock
