import React from 'react'
import Helmet from 'react-helmet'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'

import Close from 'components/svg/Close'
import { Wrapper, LargeType, Button } from 'components/Styled'

const options = [
  {
    href: `https://www.snapchat.com/`,
    label: "Snapchat",
  },
  {
    href: `Instagram://camera`,
    label: "Instagram",
  },
  {
    href: `https://tiktok.com`,
    label: "TikTok",
  },
]

const ShareWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  padding: 6rem 3rem 3rem;
`
const Hl = styled(LargeType)`
  margin-bottom: 4rem;
`
const Tools = styled.div`
  display: flex;
`
const SocialLink = styled(Button)`
  margin-top: 1.6rem;
`.withComponent("a")
const Content = styled.div`
  text-align: center;
  margin: auto 0;
`

const Index = () => {
  return (
    <ShareWrapper>
      <Helmet>
        <title>Thimble | Share</title>
      </Helmet>

      <Tools><Link to={`/after`}><Close /></Link></Tools>

      <Content>
        <Hl>Share your <br />success</Hl>
        {
          options.map( (el,i) => <SocialLink key={i} href={el.href} target="_blank" rel="noopener noreferrer">{el.label}</SocialLink> )
        }
      </Content>
      

      <Button to={`/after`}>Done</Button>
    </ShareWrapper>
  )
}

export default Index
