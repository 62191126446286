import React from 'react'

const Sun = props => <svg 
	width="305" 
	height="302" 
	viewBox="0 0 304.93 301.88"
	xmlns="http://www.w3.org/2000/svg"
  fill="currentColor"
  stroke="none"
  strokeLinecap="round" 
  strokeLinejoin="round"
  {...props}
>
<path fillRule="evenodd" d="M149.3,63.58a2.9,2.9,0,0,1-5.24.35l-32-58a2.9,2.9,0,0,0-5.43,1.62l5.1,66a2.9,2.9,0,0,1-4.57,2.58l-54-38.34A2.9,2.9,0,0,0,49,41.65L82.2,98.94a2.9,2.9,0,0,1-3,4.31L13.87,92.11a2.9,2.9,0,0,0-2.11,5.26L66.6,134.6a2.9,2.9,0,0,1-.83,5.18L2.1,158.08a2.9,2.9,0,0,0,.37,5.65L68,173.46a2.9,2.9,0,0,1,1.51,5L20.07,222.61a2.9,2.9,0,0,0,2.79,4.93L86.15,207.9a2.9,2.9,0,0,1,3.54,3.87L64.31,273A2.9,2.9,0,0,0,69,276.18L117.43,231a2.9,2.9,0,0,1,4.87,2L126,299.14a2.9,2.9,0,0,0,5.59.89l24.06-61.72a2.9,2.9,0,0,1,5.24-.35l32,58a2.89,2.89,0,0,0,5.42-1.62l-5.09-66.08a2.9,2.9,0,0,1,4.57-2.59l54,38.37a2.9,2.9,0,0,0,4.19-3.81L222.75,203a2.9,2.9,0,0,1,3-4.31l65.31,11.11a2.9,2.9,0,0,0,2.11-5.26l-54.81-37.2a2.9,2.9,0,0,1,.83-5.19l63.64-18.29a2.9,2.9,0,0,0-.37-5.66L237,128.43a2.9,2.9,0,0,1-1.5-5l49.41-44.15a2.9,2.9,0,0,0-2.79-4.93L218.81,94a2.9,2.9,0,0,1-3.54-3.88l25.39-61.18A2.91,2.91,0,0,0,236,25.69L187.52,70.84a2.9,2.9,0,0,1-4.87-2L179,2.74a2.89,2.89,0,0,0-5.59-.89Zm25.2,75.28a12.56,12.56,0,0,1,7.93-2.91,12.17,12.17,0,0,1,7.71,3.13,3,3,0,0,0,4,0,2.6,2.6,0,0,0,.07-3.89,17.88,17.88,0,0,0-11.7-4.87,18.21,18.21,0,0,0-11.93,4.48,2.61,2.61,0,0,0-.1,3.89A3,3,0,0,0,174.5,138.86ZM115,152.16a12.51,12.51,0,0,1,6.76-5.07,12.26,12.26,0,0,1,8.28.79,3.05,3.05,0,0,0,3.86-1.11,2.62,2.62,0,0,0-1.05-3.75,17.89,17.89,0,0,0-12.6-1.31,18.26,18.26,0,0,0-10.16,7.72,2.63,2.63,0,0,0,1,3.76A3,3,0,0,0,115,152.16Zm51.24,8.12a12.56,12.56,0,0,1-6.93,4.84,12.21,12.21,0,0,1-8.24-1.06,3.06,3.06,0,0,0-3.9,1,2.62,2.62,0,0,0,.93,3.78,17.9,17.9,0,0,0,12.55,1.72,18.26,18.26,0,0,0,10.4-7.38,2.61,2.61,0,0,0-.9-3.79A3,3,0,0,0,166.27,160.28Z"/>
</svg>

export default Sun