import React from 'react'

const Close = props => <svg 
	width="24" 
	height="24" 
	viewBox="0 0 24 24"
	xmlns="http://www.w3.org/2000/svg"
  fill="currentColor"
  stroke="none" 
  strokeLinecap="round" 
  strokeLinejoin="round"
  {...props}
>
<path d="M5.29,5.29a1,1,0,0,1,1.42,0L12,10.59l5.29-5.3a1,1,0,1,1,1.42,1.42L13.41,12l5.3,5.29a1,1,0,0,1-1.42,1.42L12,13.41l-5.29,5.3a1,1,0,0,1-1.42-1.42L10.59,12,5.29,6.71A1,1,0,0,1,5.29,5.29Z"/>
</svg>

export default Close
