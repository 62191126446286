import React from 'react'

const Star = props => <svg 
	width="24" 
	height="24" 
	viewBox="0 0 24 24"
	xmlns="http://www.w3.org/2000/svg"
  fill="currentColor"
  stroke="none" 
  strokeLinecap="round" 
  strokeLinejoin="round"
  {...props}
>
<path fillRule="evenodd" d="M12,2.45a1,1,0,0,1,.89.55l2.58,5.16L21.14,9a1,1,0,0,1,.56,1.7l-4.13,4,.93,5.68a1,1,0,0,1-1.46,1l-5-2.67L7,21.43a1,1,0,0,1-1.46-1l.93-5.68-4.13-4A1,1,0,0,1,2.86,9l5.67-.83L11.11,3A1,1,0,0,1,12,2.45Zm0,3.24L10.08,9.52a1,1,0,0,1-.75.54l-4.18.61,3,3a1,1,0,0,1,.29.87l-.69,4.2,3.73-2a1,1,0,0,1,.94,0l3.73,2-.69-4.2a1,1,0,0,1,.29-.87l3.05-3-4.18-.61a1,1,0,0,1-.75-.54Z"/>
</svg>

export default Star
