import React from 'react'
import Helmet from 'react-helmet'

import { ChapterWrapper, ChapterHl as Hl, Steps, ChapterSpacer } from 'components/Styled'

const options = [
  {
    to: `/during/strong`,
    label: "Get Ready",
  },
  {
    to: `/during/breath`,
    label: "Take a Breath",
  },
  {
    to: `/during/game`,
    label: "Play a Game",
  },
]


const Index = () => {
  return (
    <ChapterWrapper>
      <Helmet>
        <title>Thimble | Go time</title>
      </Helmet>
      <Hl>Take steps during your visit</Hl>
      
      <Steps 
        theme={{ bg: "var(--sky)", color: "var(--lake)" }}
        options={options}
        showLabel
      />
      <ChapterSpacer />

    </ChapterWrapper>
  )
}

export default Index;
