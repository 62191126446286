import React from 'react'
import { useLocation, Link } from 'react-router-dom'
import styled from '@emotion/styled'

import Calendar from 'components/svg/Calendar'
import Clock from 'components/svg/Clock'
import Star from 'components/svg/Star'
import { useWindowDimensions } from 'components/utility/hooks'

const Wrapper = styled.div`
  bottom: 0;
  position: absolute;
  width: 100%;
  z-index: 100;
`
const Inner = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 1.6rem 1.6rem 1.6rem;
  max-width: 39.6rem;
  width: 100%;
  margin: 0 auto;
  position: relative;
`
const Btn = styled(Link)`
  align-items: center;
  border-radius: 1.5rem;
  border: 2px solid transparent;
  display: flex;
  font-size: 1.4rem;
  height: 4.8rem;
  justify-content: center;
  padding: 1rem;
  white-space: nowrap;
  width: 10.8rem;
  & + & {
    margin-left: auto;
  }
  @media( max-width: 340px ){
    width: 33.333%;
    & + & {
      margin: 0;
    }
    &.active {
      border-color: currentColor;
    }
  }
`
const Label = styled.span`
  margin-left: .5rem;
`
const Indicator = styled.div`
  border-radius: 1.5rem;
  border: 2px solid currentColor;
  height: 4.8rem;
  left: 1.6rem;
  position: absolute;
  transition: transform .3s;
  width: 10.8rem;
  ${props => `transform: translateX(${props.theme.transform}rem);`}
  @media( max-width: 340px ){
    display: none;
  }
`

const options = [
  {
    to: `/prep`,
    icon: Calendar,
    label: "Prep",
  },
  {
    to: `/during`,
    icon: Clock,
    label: "Go time",
  },
  {
    to: `/after`,
    icon: Star,
    label: "After",
  },
]

const ChapterNav = () => {
  const location = useLocation()
  const { width } = useWindowDimensions()

  const padding = (Math.min(width, 396) - 356) / 20 //in rem
  const optionIndex = options.findIndex( el => location.pathname.indexOf(el.to) === 0  )
  const transform = (10.8 + padding) * optionIndex

  return (
    <Wrapper>
      <Inner>
      <Indicator theme={{ transform, optionIndex }} />
      {
        options.map( el => <Btn className={location.pathname.indexOf(el.to) === 0 ? "active" : null} key={el.label} to={el.to}>
            <el.icon />
            <Label>{el.label}</Label>
          </Btn> 
        )
      }
      </Inner>
    </Wrapper>
  );
}

export default ChapterNav
