import React, { useState } from 'react'
import Helmet from 'react-helmet'
import styled from '@emotion/styled'

import { version } from 'config/vars'
import { Wrapper, Button1 as Button } from 'components/Styled'


const Label = styled.p`
  font-size: 1.4rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 2rem;
`

const feedbackOptions = [
  { 
    to: "mailto:md@acheloncompany.com?subject=Thimble%20App%20General",
    label: "General",
  },
  { 
    to: "mailto:md@acheloncompany.com?subject=Thimble%20App%20Feedback",
    label: "Give feedback",
  },
  { 
    to: "mailto:md@acheloncompany.com?subject=Partner%20with%20Thimble",
    label: "Partner with us",
  },
]

const InputWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  position: relative;
  width: 32rem;
  @media( max-width: 340px ){
    width: 100%
  }
`
const RadioButton = styled.button`
  align-items: center;
  background: none;
  border-radius: 1.5rem;
  border: 2px solid transparent;
  display: flex;
  font-size: 2.1rem;
  height: 4.8rem;
  justify-content: center;
  padding: 1rem;
  position: relative;
  width: 15rem;
  & + & {
    margin-left: 2rem;
  }
`
const Indicator = styled.div`
  border-radius: 1.5rem;
  border: 2px solid currentColor;
  height: 4.8rem;
  left: 0;
  position: absolute;
  transition: transform .3s;
  width: 15rem;
  ${props => props.theme.shift ? `transform: translateX(17rem);` : null}
  @media( max-width: 340px ){
    width: calc((100vw - 60px )/2 );
    ${props => props.theme.shift ? `transform: translateX(calc((100vw - 60px )/2 + 20px ));` : null}
  }
`

const languageOptions = [
  { 
    value: "en",
    label: "English",
  },
  { 
    value: "es",
    label: "Spanish",
  },
]


const LanguageRadio = ({ className, value, onChange }) => {

  const onClick = (val) => {
    return
    onChange(val)
  }

  return (
    <InputWrapper>
      <Indicator theme={{ shift: value === "es"}} />
      { languageOptions.map( el => <RadioButton type="button" key={el.value} onClick={() => onClick(el.value)}>{el.label}</RadioButton> )}
    </InputWrapper>
  )
}

const CenterWrapper = styled(Wrapper)`
  background: #fff;
  color: #000;
  display: flex;
  align-items: center;
`
const Inputs = styled.div`
  width: 100%;
  min-width: 0;
  max-width: 34rem;
  margin: 0 auto;
`
const Field = styled.div`
  & + & {
    margin-top: 6rem;
  }
`
const SettingButton = styled(Button)`
  & + & {
    margin-top: 1.6rem;
  }
`
const Version = styled.p`
  bottom: 2rem;
  color: #999;
  right: 2rem;
  position: absolute;
`
const Settings = () => {
  const [language, setLanguage] = useState("en")
  return (
    <CenterWrapper>
      <Helmet>
        <title>Settings</title>
      </Helmet>
      <Inputs>
        <Field>
          <Label>Language preference <br />(coming soon)</Label>
          <LanguageRadio
            value={language}
            onChange={val => setLanguage(val)}
          />
        </Field>
        <Field>
          <Label>Contact Us</Label>
          { feedbackOptions.map( el => <SettingButton as="a" href={el.to}>{el.label}</SettingButton> )}
        </Field>
      </Inputs>
      <Version>Version {version}</Version>
    </CenterWrapper>
  );
}

export default Settings
