import React from 'react'
import Helmet from 'react-helmet'
import styled from '@emotion/styled'

import { ChapterWrapper, ChapterHl as Hl, Steps, ChapterSpacer } from 'components/Styled'

const options = [
  {
    to: `/prep/list`,
    label: <span>How to prepare kids for <br />needle procedures</span>,
  },
]

const StyledSteps = styled(Steps, {
  shouldForwardProp: () => true
})`
  @media( min-width: 340px ){
    .step {
      height: 33.2rem;
    }
  }
`

const Index = () => {
  return (
    <ChapterWrapper>
      <Helmet>
        <title>Thimble | Get ready</title>
      </Helmet>

      <Hl>Get ready for your appointment</Hl>
      
      <StyledSteps 
        theme={{ bg: "var(--lavender)", color: "var(--berry)" }}
        options={options}
      />
      <ChapterSpacer />
    </ChapterWrapper>
  )
}

export default Index;
